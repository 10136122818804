import React from 'react';
import './App.css';
import Home from "./components/Home";
import {FirebaseProvider} from "./firebase/firebase";


function App() {

  return (
      <FirebaseProvider>
        <Home/>
      </FirebaseProvider>
  );
}



export default App;
