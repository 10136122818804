import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import Typography from '../components/Typography';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import {HomeContext} from "../../Home";
import RegisterDialog from "../../RegisterDialog";
import TermsDialog from "../../TermsDialog";


function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit" href=".">
         LoKi {new Date().getFullYear()}
      </Link>
    </React.Fragment>
  );
}
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),

    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  link: {
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.secondary.main,
      cursor: 'pointer'
    }
  },
  social: {
    '&:hover' : {
      color: theme.palette.secondary.main,
      cursor: 'pointer'
    }
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

const LANGUAGES = [
  {
    code: 'en-US',
    name: 'English',
  },
  {
    code: 'fr-FR',
    name: 'Français',
  },
];

export default function AppFooter() {
  const classes = useStyles();
  const {setContactOpen, setTermsOpen, setPrivacyOpen} = React.useContext(HomeContext)
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" component="footer" className={classes.container}>
        <Grid container spacing={2} justify='center' alignItems={'flex-start'}>
          <Grid item xs={12} md={4} spacing={1} container justify={'center'} alignContent={'center'}>
            <Grid item xs={12}>
              <Typography align={'center'} variant={'h6'} onClick={() => setContactOpen(true)} className={classes.link}>Contact Us</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} className={classes.link}>support@shoploki.com</Typography>
            </Grid>

          </Grid>
          <Grid item xs={12} md={4} spacing={1} container justify={'center'} alignContent={'center'}>
            <Grid item xs={12}>
              <Typography variant={'h6'} align={'center'}>social media</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align={'center'} onClick={() => window.open('https://instagram.com/shop.loki/', '_blank')}><InstagramIcon className={classes.social}/></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align={'center'} onClick={() => window.open('https://facebook.com/shop.loki/', '_blank')}><FacebookIcon className={classes.social}/></Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align={'center'} onClick={() => window.open('https://twitter.com/_shoploki/', '_blank')}><TwitterIcon className={classes.social}/></Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} spacing={1} container justify={'center'} alignContent={'center'}>
            <Grid item xs={12}>
              <Typography align={'center'} variant={'h6'}>Other Links</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} className={classes.link} onClick={() => setTermsOpen(true)}>Terms of Service</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} className={classes.link}onClick={() => setPrivacyOpen(true)}>Privacy Policy</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} className={classes.link}>My Profile</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align={'center'} className={classes.link}>Home</Typography>
            </Grid>

          </Grid>
          <RegisterDialog/>
          <Grid item xs={12} container justify={'center'}>
            <Copyright/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
