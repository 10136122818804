import React from 'react'
import Grid from '@material-ui/core/Grid'
import {HomeContext} from "./Home";
import XLSX from 'xlsx'
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CjParser from "../parsers/CjParser";
import _ from 'lodash'
import {FirebaseDB} from "../firebase/firebase";
import Select from 'react-select'
import FlexParser from "../parsers/FlexParser";


export default function FileUpload() {
    const {setLoading, setError, setMessage, setSuccess} = React.useContext(HomeContext)
    const [type, setType] = React.useState({})
    const [file, setFile]  = React.useState()
    const [products, setProducts] = React.useState([])
    const [categories, setCategories] = React.useState([])
    const [gender, setGender] = React.useState()


    const getWorkbook = (e,f) => {
        let reader = new FileReader()
        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                let data = new Uint8Array(e.target.result)
                let workbook = XLSX.read(data, {type: 'array'})
                resolve(workbook)
            }
            reader.readAsArrayBuffer(f)
        })
    }

    const runParse = () => {
        setLoading(true)
        if (type.value === 'CJ') {
            CjParser(file)
                .then(res => {
                    console.log(res)
                    setProducts(res)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
        else if (type.value === 'Flex') {
            FlexParser(file)
                .then(res => {
                    console.log(res)
                    setProducts(res)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }



    const handleFileSelect = (e) => {
        setLoading(true)
        getWorkbook(e, !_.isUndefined(e.target.files) ? e.target.files[0] :'None')
            .then(workbook => {
                setFile(workbook)
                setLoading(false)
            })
            .catch(err => {
                setError(true)
                setMessage('File upload failed. Please try again later. If the problem persists, contact support')
            })
    }



    const uploadProducts = e => {
        e.preventDefault()
        setLoading(true)
        console.log("Adding products" , products)
        let promises = []
        products.forEach((product,index) => {
            promises.push(FirebaseDB.collection('scriptProducts').doc(product.name.replace(new RegExp('/','g'), '|')).set(
                {...product,
                    name: product.name.replace(new RegExp('/','g'), '|'),
                    categories: categories.length > 0 ? categories.map(x => x.value):product.category ? product.category: [],
                    gender: gender && gender.value ? gender.value:''
                }
        ))})
        Promise.all(promises)
            .then(() => {
                setSuccess(true)
                setMessage("Products uploaded successfully")
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setMessage("Something went wrong when trying to upload all products. Check the console for the specific error")
                setError(true)
                setLoading(false)
            })
    }

    let typeOptions = React.useMemo(() => {
        return [
            'Flex',
            'CJ'
        ].map(x => {
            return {label: x, value: x}
        })
    },[])



    const categoryOptions = [
        'Lounge',
        'Active',
        'Shoes',
        'Tops',
        'Bottoms',
        'Equipment',
        'Other'
    ].map(x => {
        return {label: x, value: x}
    })

    const genderOptions = ['Male', 'Female'].map(x => {
        return {label: x, value: x}
    })

    return (
            <Container maxWidth={'lg'} style={{marginTop: 50}}>
                <Grid item container xs={12}>
                    <Grid item xs={12}>
                        <Typography>
                            Upload a formatted CJ or Flex File
                        </Typography>
                        <TextField fullWidth type={'file'} onChange={(e) => handleFileSelect(e)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'caption'}>
                            Select a type of file
                        </Typography>
                        <Select options={typeOptions} value={type} onChange={x => setType(x)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'caption'}>
                            Select categories (optional)
                        </Typography>
                        <Select isMulti options={categoryOptions} value={categories} onChange={x => setCategories(x)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'caption'}>
                            Select categories (optional)
                        </Typography>
                        <Select options={genderOptions} value={gender} onChange={x => setGender(x)}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={runParse} disabled={!file || type === {}} fullWidth>
                            Parse File
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {products.length > 0 &&
                        <Button fullWidth onClick={uploadProducts} disabled={products.length < 1}>
                            upload {products.length} products
                        </Button>}
                    </Grid>
                </Grid>
            </Container>
    )
}