import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {FirebaseContext, FirebaseDB} from "../firebase/firebase";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import uuidv4 from 'uuid/v4'
import DeleteIcon from '@material-ui/icons/Delete';
import {HomeContext} from "./Home";
import {Tooltip} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";
import Slide from 'react-reveal'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditDialog from "./EditDialog";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: "0 10px 20px rgba(0,0,0,0.15)"
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
    card: {
        maxWidth: 250,
    },
    media: {
        height: 350,

    },

    name: {
        maxHeight: 100,
        overflow: 'hidden',
        '&:hover': {
            color: theme.palette.secondary.main,
            textDecoration: 'bold'
        }
    },
    seeMore: {
        '&:hover': {
            cursor: 'pointer',
            fontWeight: 'bold'
        }
    }

}));
export function ProductCard(props) {
    const {user} = React.useContext(FirebaseContext)
    const [saved, setSaved] = React.useState(false)
    const [showPartner, setShowPartner] = React.useState(false)
    const [editing, setEditing] = React.useState(false)
    const [editProps, setEditProps] = React.useState({})
    const [showDesc, setShowDesc] = React.useState(false)
    const [logo, setLogo] = React.useState('https://firebasestorage.googleapis.com/v0/b/shop-loki.appspot.com/o/LoKi%20Black%20Background.png?alt=media&token=a43b749a-4363-4361-bcc1-17a4df8fc96d')
    const {setRegisterOpen, setSuccess, setError, setMessage, setLoading, partners} = React.useContext(HomeContext)



    React.useEffect(() => {
        if (partners && props && props.seller && partners.length > 0) {
            partners.forEach(partner => {
                if (partner.name.toLowerCase() === props.seller.toLowerCase()) {
                    setLogo(partner.logoUrl)
                }
            })
        }
    }, [props.seller, partners]);

    const saveToWishlist = () => {
        setSuccess(false)
        setLoading(true)
        setError(false)
        if (user) {
            setSaved(true)
            FirebaseDB.collection('users').doc(user.uid).collection('wishlist').doc(uuidv4()).set({
                name: props.name ? props.name: 'NO NAME',
                price: props.price ? props.price: '',
                picUrl: props.picUrl ? props.picUrl: '',
                url: props.url ? props.url :' ',
                desc: props.desc ? props.desc: 'No description',
                categories: props.categories ? props.categories:'',
                seller: props.seller ? props.seller:'',
            })
                .then(res => {
                    setLoading(false)
                    setSuccess(true)
                    setMessage("This product has been added to your wishlist")

                })
                .catch(err => {
                    console.log(err)
                    setSaved(false)
                    setLoading(false)
                    setSuccess(false)
                    setError(true)
                    setMessage("Wishlist addition failed. Please try again later")
                })
        }
        else {
            setError(true)
            setMessage("You must be logged in to an account to save products to your wishlist. Sign up or log in now!")
            setRegisterOpen(true)
            setLoading(false)
        }
    };
    const goToUrl = () => {
        if (props.url) {
            window.open(props.url, '_blank')
        }
        else {
            setError(true)
            setMessage("URL NOT FOUND. PLEASE CONTACT SUPPORT")
        }
    }


    const deleteProduct = (name, seller) => {
        setLoading(true)
        FirebaseDB.collection('products').doc(`${name}${seller}`.replace('/','').replace('/','').replace('/','')).delete()
            .then(res => {
                setSuccess(true)
                setMessage('Product deleted successfully')
                setLoading(false)
            })
            .catch(err => {
                setError(true)
                setMessage('Product could not be deleted')
                console.log(err)
                setLoading(false)
            })

    }



    const handleEdit = (product) => {
        setEditProps(product)
        setEditing(true)
    }


    const classes = useStyles();
    return (
        <>
            <Grid item xs={12}>
            <Card className={classes.root}>
                <CardActionArea className={classes.action} onClick={goToUrl}>
                    <Slide right><CardMedia
                        className={classes.media}
                        image={props.partnerDisplay ? props.picUrl : showPartner ? logo: props.picUrl ? props.picUrl: logo}
                        title={props.name}
                        onMouseEnter={() => setShowPartner(true)}
                        onMouseLeave={() => setShowPartner(false)}

                    /></Slide>

                    {props.price &&
                    <CardContent className={classes.content}>
                        <Typography variant={'body2'} color={'secondary'}>
                            {props.seller}
                        </Typography>
                        <Typography variant="h5" component="h2" color={'primary'} className={classes.name} noWrap={!showDesc} gutterBottom>
                            {props.name}
                        </Typography>
                        <Typography gutterBottom variant={'h6'} color={'secondary'}>{props.price ?`$${(+props.price).toFixed(2)}`:''}</Typography>
                        <Divider/>
                        <Collapse in={showDesc}>
                        <Typography variant="body2" color="primary" component="p">
                            {props.desc}
                        </Typography>
                            <Divider/>
                            <Typography variant={'caption'} color={'secondary'}>See more options</Typography>
                        </Collapse>
                    </CardContent>}
                </CardActionArea>
                <CardActions style={{display: 'flex'}}>
                    {user && user.roles && user.roles.indexOf('admin') !== -1 &&
                    <>
                        <Button onClick={() => deleteProduct(props.name, props.seller)}>
                            <DeleteOutlineOutlinedIcon/>
                        </Button>
                        <Button onClick={() => handleEdit({...props})}>
                            <EditOutlinedIcon/>
                        </Button>
                    </>}
                    {props.delete &&
                        <Tooltip title={'Remove from wishlist'}>
                    <Button onClick={props.delete}>
                        <DeleteIcon/>
                    </Button>
                        </Tooltip>}
                    {props.desc &&
                    <Tooltip title={'Show more'} placement={'bottom'}>
                        <Button onClick={() => setShowDesc(!showDesc)}>
                        {showDesc ? <ExpandLessIcon/>:<ExpandMoreIcon/>}
                    </Button>
                    </Tooltip>}
                    <> {props.actions && props.actions.map(action => {
                        return( <Button key={uuidv4()} color={saved ? 'secondary' : 'primary'} onClick={action.func}>
                            {action.icon}
                                </Button>)
                    })
                    }
                        {props.url &&
                            <>
                        <Tooltip title={'Save to wishlist'} placement={'bottom'}>
                            <Button color={saved ? 'secondary' : 'primary'} onClick={() => saveToWishlist()}>
                            <WhatshotIcon/>
                        </Button>
                        </Tooltip>
                        <Button variant={'contained'} color={'secondary'} onClick={goToUrl}>
                            Check it out
                            </Button>
                                </>
                        }
                    </>
                </CardActions>

            </Card>
            </Grid>
            {editing && <EditDialog open={editing} close={() => setEditing(false)} {...editProps}/>}
        </>
    );
}


