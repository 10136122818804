import React from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel'
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "../components/Typography";
import {HomeContext} from "../../Home";
import {ProductCard} from "../../ProductCards";
import ContactDialog from "../../ContactDialog";

const useStyles = makeStyles(theme => ({
    image: { /* Safari 6.0 - 9.0 */
        '&:hover': {
            opacity: .9,
            cursor: 'pointer'
        },
        width: '100%',
        height: 'auto',

        justifySelf: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4)
    },
    paper: {
        height: 300,
    },
    title: {
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    }
}))



const PartnerCarousel = props => {
    const classes = useStyles()
    const [items, setItems] = React.useState([])
    const {partners} = React.useContext(HomeContext)


    React.useEffect(() => {
        let itemz = []
        partners.forEach(partner => {
            itemz.push(
                    <ProductCard logoUrl={partner.logoUrl} picUrl={partner.logoUrl} partnerDisplay/>
            )
        })
        setItems(itemz)
    }, [partners])


    let responsive = {
        0: { items: 1 },
        640: { items: 2},
        1024: { items: 3 },
        1480: {items: 4},
    }
    const onSlideChange = e => {
        console.debug('Item`s position during a change: ', e.item)
        console.debug('Slide`s position during a change: ', e.slide)
    }
    const onSlideChanged = e => {
        console.debug('Item`s position after changes: ', e.item)
        console.debug('Slide`s position after changes: ', e.slide)
    }

        return (
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.title}>
                    <Typography variant={'h4'} align={'center'}>Our Partners</Typography>
                </Grid>
                    <AliceCarousel onSlideChange={onSlideChange}
                                   onSlideChanged={onSlideChanged}
                                   responsive={responsive}
                                   items={items}
                                   autoPlayInterval={1000}
                                   autoPlayDirection={'ltr'}
                                   autoPlay={true}
                                   fadeOutAnimation={false}
                                   mouseTrackingEnabled={true}
                                   playButtonEnabled={false}
                                   dotsDisabled
                                   buttonsDisabled

                    />
                <ContactDialog/>
            </Grid>

        );

}
export default PartnerCarousel
