import React,{useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from 'react-select'
import {makeStyles} from "@material-ui/core/styles";
import {FirebaseDB} from '../firebase/firebase'
import Typography from "./modules/components/Typography";
import Button from "./modules/components/Button";
import {HomeContext} from "./Home";
import {ProductCard} from "./ProductCards";
import {functions} from "../firebase/backend";
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditDialog from "./EditDialog";
import _ from 'lodash'
import {Container} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";


const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    }

}))

export default function ProductAdder(props) {
    const classes = useStyles()
    const [name, setName] = React.useState('')
    const [url, setUrl] = React.useState('')
    const [logoUrl, setLogoUrl] = React.useState('')
    const [api, setApi] = React.useState('')
    const [categories, setCategories] = React.useState([])
    const [disabled, setDisabled] = React.useState(true)
    const [keyword, setKeyword] = React.useState('')
    const [search, setSearch] = React.useState('')
    const [generic, setGeneric] = React.useState(true)
    const [editProps, setEditProps] = React.useState('')
    const [exclude, setExclude] = React.useState('')
    const [editOpen, setEditOpen] = React.useState(false)
    const [partnerOptions, setPartnerOptions] = React.useState([])
    const [potentialProducts, setPotentialProducts] = React.useState([])
    const [partner, setPartner] = React.useState(false)
    const {setLoading, setSuccess, setError, setMessage, partners} = React.useContext(HomeContext)

    
    const categoryOptions = [
        'Lounge',
        'Active',
        'Shoes',
        'Tops',
        'Bottoms',
        'Equipment',
        'Other'
    ].map(x => {
        return {label: x, value: x}
    })


    const apiOptions = [
    /*    {label: 'Amazon', value: 'Amazon'},*/
        {label: 'Rakuten', value: 'Rakuten'},
        {label: 'Impact Radius', value: 'Impact'},
        {label: 'CJ', value: 'CJ'},
        {label: 'Flex Offers', value: 'Flex Offers'},


    ]

    React.useEffect(() => {
        let partnerList = [...partners]
        partnerList = _.filter(partnerList, x => x.id && x.api && api.label && x.api.toLowerCase().indexOf(api.label.toLowerCase()) !== -1)
        let arr = partnerList.map(x => {
            return({label: x.name, value: x.id})
        })
        setPartnerOptions(arr)

    }, [partners, api])

    React.useEffect(() => {
        setSuccess(false)
        if (keyword !== '' && categories !== []) {
            setDisabled(false)
        } else setDisabled(true)
    }, [api, keyword]);


    const clear = () => {
        setName('');
        setUrl('');
        setLogoUrl(0);
        setApi('');
        setCategories([]);
        setKeyword('');
        setPotentialProducts([])
    }

    React.useEffect(() => {
        console.log(keyword)
    })

    const getSpecificProducts = () => {
        switch(api.label) {
            case 'Rakuten': {
                let arr = []
                let getRakuten = functions.httpsCallable('rakutenWithId')
                getRakuten({keyword: keyword, none: exclude, id: partner.value})
                    .then(res => {
                        res.data.forEach(product => {
                            arr.push(product)
                        })
                        return arr
                    })
                    .then(arr => {
                        setPotentialProducts(arr)
                        setLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setLoading(false)
                    })
                break
            }
            case 'CJ': {
                let arr = []
                let getCJ = functions.httpsCallable('cjWithId')
                getCJ({keyword: keyword, id: partner.value})
                    .then(res => {
                        res.data.forEach(product => {
                            arr.push(product)
                        })
                        return arr
                    })
                    .then(arr => {
                        setPotentialProducts(arr)
                        setLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setLoading(false)
                    })
                break
            }
            case 'Flex Offers': {
                let arr = []
                let getFlex = functions.httpsCallable('flexWithId')
                getFlex({keyword: keyword, id: partner.label})
                    .then(res => {
                        res.data.forEach(product => {
                            arr.push(product)
                        })
                        return arr
                    })
                    .then(arr => {
                        setPotentialProducts(arr)
                        setLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setLoading(false)
                    })
                break
            }
            default: {
                setLoading(false)
                setError(true)
                setMessage("Options selected are currently unavailable")
            }
        }
    }

    const getProducts = () => {
        setLoading(true)
        if (generic) {
            let arr = []
            let promises = []
            let getRakuten = functions.httpsCallable('rakuten')
            promises.push(getRakuten({keyword: keyword, none: exclude})
                .then(res => {
                    res.data.forEach(product => {
                        arr.push(product)
                    })
                    return arr
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                }))
            let getFlex = functions.httpsCallable('flex')
            promises.push(getFlex({keyword: keyword})
                .then(res => {
                    res.data.forEach(product => {
                        arr.push(product)
                    })
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                }))
            let getCJ = functions.httpsCallable('cj')
            promises.push(getCJ({keyword: keyword})
                .then(res => {
                    res.data.forEach(product => {
                        arr.push(product)
                    })
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                }))
            let getImpact = functions.httpsCallable('impact')
            promises.push(getImpact({keyword: keyword})
                .then(res => {
                    res.data.forEach(product => {
                        arr.push(product)
                    })
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                }))


            Promise.all(promises).then(res => {
                setPotentialProducts(arr)
                setLoading(false)
            })
                .catch(err => {
                    setError(true)
                    setMessage("Something went wrong when retrieving products. Please wait a few minutes and then try again. If the problem persists, contact Marcus :D")
                    console.log(err)
                    setLoading(false)
                })
        }
        else {
            getSpecificProducts()
        }
    }


        const addProduct = (e, product) => {
            e.preventDefault()
            setLoading(true)
            FirebaseDB.collection('products').doc(`${product.name.replace('/','').replace('/','').replace('/','')}${product.seller}`).set({
                ...product,
                categories: categories.map(x => x.label)
            })
                .then(res => {
                    console.log(res)
                    setSuccess(true)
                    setMessage('Product successfully added to the database')
                    setLoading(false)
                })
                .catch(err => {
                    setError(true)
                    setMessage("Something went wrong. We were unable to add the product to the database")
                    setLoading(false)
                })
        }

        const handleEdit = (product) => {
            console.log(product)
            setEditProps(product)
            setEditOpen(true)
        }

        return (
            <Grid item xs={12} container justify={'center'} alignItems={'center'} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} align={'center'}>API Product Adder</Typography>
                </Grid>
                <Grid item xs={12} md={10} container>
                    <Paper className={classes.paper}>
                        <Grid container justify={'center'} alignItems={'center'} spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant={'h4'} align={'center'}>API Products</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button size={'small'} color='secondary' onClick={clear} fullWidth>Clear</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Switch checked={generic} onClick={() => setGeneric(!generic)}/>
                                Generic Search?
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={'Product keywords'} value={keyword}
                                           onChange={(e) => setKeyword(e.target.value)} fullWidth variant={'outlined'}/>
                            </Grid>
                            {!generic &&
                            <>
                            <Grid item xs={12}>
                                <Select options={apiOptions} value={api} onChange={x => setApi(x)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Select options={partnerOptions} value={partner} onChange={x => setPartner(x)}/>
                            </Grid>
                            </>}
                            <Grid item xs={12}>
                                <TextField label={'Exclude'} value={exclude}
                                           onChange={(e) => setExclude(e.target.value)} fullWidth variant={'outlined'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Select options={categoryOptions} value={categories} onChange={x => setCategories(x)}
                                        placeholder={'Select Categories'} isMulti/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant={'outlined'} onClick={getProducts} disabled={disabled}>Get
                                    products</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <EditDialog open={editOpen} {...editProps} close={() => setEditOpen(false)}/>
                    <Container maxWidth={'lg'}>
                    <Grid container justify={'center'} alignItems={'center'} spacing={2}>
                        {React.useMemo(() => {
                            return (
                                <>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Number of products returned : {potentialProducts.length}
                                        </Typography>
                                    </Grid>
                                    {potentialProducts && potentialProducts.length > 0 ?
                                        <>
                                            {potentialProducts.map(product => {
                                                    return (
                                                        <Grid item xs={12} sm={4} md={4}
                                                              key={`${product.name}${product.seller}`}>
                                                            <ProductCard
                                                                key={`${product.name}${product.seller}`} {...product}
                                                                actions={
                                                                    [
                                                                        {
                                                                            func: (e) => addProduct(e, product),
                                                                            icon: <AddIcon/>
                                                                        },
                                                                        {
                                                                            icon: <EditOutlinedIcon/>,
                                                                            func: () => handleEdit(product)
                                                                        }]}/>
                                                        </Grid>
                                                    )
                                                }
                                            )
                                            }
                                        </> :
                                        <>No products</>}

                                </>
                            )

                        }, [potentialProducts])}
                    </Grid>
                    </Container>
                </Grid>
            </Grid>
        )
    }

