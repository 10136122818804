import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';

import Flip from 'react-reveal/Flip'
import Slide from 'react-reveal/Slide'
import {HomeContext} from "../../Home";
const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
});

function ProductSmokingHero(props) {
  const { classes } = props;
  const {setContactOpen} = React.useContext(HomeContext)

  return (
    <Container className={classes.root} component="section">
      <Flip top>
        <Button className={classes.button} onClick={() => setContactOpen(true)}>
          <Typography variant="h4" component="span">
            Have any questions?
          </Typography>
        </Button>
      </Flip>
      <Slide right>
        <Typography variant="subtitle1" className={classes.link}>
          We are here to help. Get in touch!
        </Typography>
      </Slide>
      <Slide left>
        <Button fullWidth onClick={() => setContactOpen(true)} variant={'outlined'} color={'secondary'}>Contact Us</Button>
      </Slide>
    </Container>
  );
}

ProductSmokingHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductSmokingHero);
