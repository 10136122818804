import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import {ProductCard} from './ProductCards'
import Typography from '@material-ui/core/Typography';
import {Container} from "@material-ui/core";
import {FirebaseContext, FirebaseDB} from "../firebase/firebase";
import TextField from "@material-ui/core/TextField";
import {HomeContext} from "./Home";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
    card: {
        maxWidth: 250,
    },
    media: {
        height: 250,
    },
}));

export default function UserProfile(props) {
    const classes = useStyles()
    const {user, logout,isUserLoading}  = React.useContext(FirebaseContext)
    const [wishlist, setWishlist] = React.useState([])
    const [search, setSearch] = React.useState('')
    const [filteredList, setFilteredList] = React.useState([])
    const [adminOpen, setAdminOpen] = React.useState(false)
    const [component, setComponent] = React.useState(<></>)
    const {setLoading, setSuccess, setError, setMessage, products, partners} = React.useContext(HomeContext)

    React.useEffect(() => {
        setLoading(true)
    }, [])

    React.useEffect(() => {
        let arr = [...wishlist]

        arr = arr.map(x => {
             if((x && x.name && x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)) {
                 return x
             }
        })
        setFilteredList(arr)
    },[search])

    React.useEffect(() => {
        getWishlist()
    }, [isUserLoading])

    const deleteWish = (id) => {
        setLoading(true)
        setSuccess(false)
        setError(false)
        if (user) {
            FirebaseDB.collection('users').doc(user.uid).collection('wishlist').doc(id).delete()
                .then(res => {
                    setSuccess(true)
                    setMessage("Product removed from wishlist")
                    })
                .then(() => {
                    getWishlist()
                })
                .catch(err => {
                    setError(true)
                    setMessage('Uh oh. Something went wrong')
                })
        }
    }

    const getWishlist = () => {
        if (user) {
            FirebaseDB.collection('users').doc(user.uid).collection('wishlist').get()
                .then(docs => {
                    let arr = []
                    docs.forEach(doc => {
                        let document = doc.data()

                        arr.push({...document, id: doc.id})
                    })
                    console.log(arr)
                    setWishlist(arr)
                    setLoading(false)
                    setFilteredList(arr)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }
    const buttonRef = React.createRef()

    const handleReadProducts = (data) => {
        let products = data.map(row => {return row.data})
        console.log(products)
        let promises = []
        products.forEach(product => {
            promises.push(FirebaseDB.collection('products').doc(`${product.name}${product.seller}`).set(
                {
                    name: product.name ? product.name: 'None',
                    seller: product.seller ? product.seller: 'None',
                    desc: product.desc ? product.desc: 'None',
                    picUrl: product.picUrl ? product.picUrl: 'None',
                    categories: product.categories ? product.categories: [],
                    url: product.url ? product.url: 'None',
                    price: product.price ? product.price: '0'
                }
            ))
            /*FirebaseDB.collection('products').doc(`${product.name}${product.seller}`).set({product})
                .then(res => console.log(res))
                .catch(err => console.log(err))*/
        })
        Promise.all(promises)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleReadPartners = (data) => {
        let partners = data.map(row => {return row.data})

        let promises = []
        partners.forEach(partner => {
            promises.push(FirebaseDB.collection('partners').doc(partner.name).set(partner))
        })
        Promise.all(promises)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }
    const openProductDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }
    const openPartnerDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }




    return (
        <Container className={classes.root} component="section">
            {user &&
            <Grid container spacing={2} xs={12} item justify={'center'}>
                <Grid item xs={12}><Typography variant={'h4'} align={'center'}>{user.name}</Typography></Grid>
                <Grid item xs={12}><Typography variant={'button'} color={'primary'}>My Wishlist</Typography> </Grid>
                <Grid item xs={12}>
                    <Typography color={'secondary'}>
                    You have {filteredList.length} products in your wishlist
                    </Typography>
                </Grid>
                <Grid item xs={12}><TextField variant={'outlined'} fullWidth label={'Search'} value={search} onChange={e => setSearch(e.target.value)} color={'secondary'}/></Grid>
                {filteredList.map((x,index) => {
                    return (
                    <>    {x &&
                    <Grid item xs={12} md={4} sm={6}>
                        <ProductCard key={index} delete={() => deleteWish(x.id)} name={x.name} url={x.picUrl} index={index} desc={x.desc} logoUrl={x.logoUrl}
                                             seller={x.seller} picUrl={x.picUrl} price={x.price}/>
                    </Grid>}</>
                    )
                })}
            </Grid>
                }
        </Container>


    )

}
