import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import {HomeContext} from "../../Home";
import AliceCarousel from "react-alice-carousel";
import {ProductCard} from "../../ProductCards";



const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.secondary.light,
        overflow: 'hidden',
        minHeight: 700,
    },
    container: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(15),
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(0, 5),
    },
    title: {
        marginBottom: theme.spacing(14),
    },
    number: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
    },
    image: {
        height: 200,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        borderRadius: 20,
        '&:hover': {
            opacity: .8
        }
    },
    curvyLines: {
        pointerEvents: 'none',
        position: 'absolute',
        top: -180,
        opacity: 0.7,
    },
    button: {
        marginTop: theme.spacing(8),
    },
});

function FeaturedProducts(props) {
    const { classes } = props;
    const [items, setItems] = React.useState([])
    const {featuredProducts} = React.useContext(HomeContext)

    React.useEffect(() => {
        let itemz = []
        featuredProducts.forEach(product => {
            itemz.push(
                <ProductCard name={product.name} logoUrl={product.logoUrl} picUrl={product.picUrl} seller={product.seller} desc={product.desc} price={product.price} url={product.url}/>
            )
        })
        setItems(itemz)
    }, [featuredProducts])

    let responsive = {
        0: { items: 1 },
        640: { items: 2},
        1024: { items: 4 },
        1480: {items: 6},
    }
    const onSlideChange = e => {
        console.debug('Item`s position during a change: ', e.item)
        console.debug('Slide`s position during a change: ', e.slide)
    }
    const onSlideChanged = e => {
        console.debug('Item`s position after changes: ', e.item)
        console.debug('Slide`s position after changes: ', e.slide)
    }


    return (
        <section className={classes.root}>
            <Container className={classes.container} maxWidth={'xl'}>
                <Typography variant="h4" marked="center" className={classes.title} align='center' component="h2">
                   Featured Products
                </Typography>
                <AliceCarousel onSlideChange={onSlideChange}
                               onSlideChanged={onSlideChanged}
                               responsive={responsive}
                               items={items}
                               autoPlayInterval={2000}
                               autoPlayDirection={'rtl'}
                               autoPlay={true}
                               fadeOutAnimation={false}
                               mouseTrackingEnabled={true}
                               playButtonEnabled={false}
                               dotsDisabled
                               buttonsDisabled

                />
            </Container>
        </section>
    );
}

FeaturedProducts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeaturedProducts);
