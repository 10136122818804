import React from 'react'
import {FirebaseDB} from "../firebase/firebase";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {ProductCard} from "./ProductCards";
import {HomeContext} from "./Home";
import Button from "@material-ui/core/Button";
import Typography from "./modules/components/Typography";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import _ from 'lodash'
import algoliasearch from "algoliasearch";
import TextField from "./modules/components/TextField";

export default function ScriptProductManager() {
    const {setLoading, setError, setMessage, setSuccess} = React.useContext(HomeContext)
    const [products, setProducts] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [selected, setSelected] = React.useState([])
    const [filter, setFilter] = React.useState('')

    const ref = React.createRef()
    React.useEffect(() => {
        setLoading(true)
        FirebaseDB.collection('scriptProducts').get()
            .then(res => {
                let arr = []
                setLoading(false)
                res.forEach(doc => {
                    arr.push({...doc.data(), id: doc.id})
                })
                return arr
            })
            .then(arr => {
                setProducts(_.filter(arr, x => x.price > 0))
            })
            .catch(err => {
                console.error(err)
                setLoading(false)
                setError(true)
                setMessage("SOMETHING WENT WRONG WHEN TRYING TO RECEIVE SCRIPT PRODUCTS")
            })
    },[])

    const scrollToTop = () => {
        if (ref.current) {
            ref.current.scrollIntoView({behavior:'smooth', block:'start'})
        }
    }

    const deleteAll = () => {
        setLoading(true)
        let promises = []
        selected.forEach(index => {
            promises.push(FirebaseDB.collection('scriptProducts').doc(products[index].name).delete())
        })
        Promise.all(promises)
            .then(res => {
                setLoading(false)
                let arr = _.cloneDeep(products)
                for (let i = selected.length - 1; i>=0; i--) {
                    arr.splice(selected[i],1)
                }
                setProducts(arr)
                setSuccess(true)
                setMessage("Products deleted successfully")
            })
            .catch(err => {
                setLoading(false)
                setError(true)
                setMessage("Something went wrong when trying to delete all products. Please let Marcus know that this happened.")
            })


    }


    const toggleSelect = index => {
        let arr = [...selected]
        if (arr.indexOf(index) === -1) arr.push(index)
        else arr.splice(arr.indexOf(index),1)
        setSelected(arr)
    }


    const selectAll = () => {
        let arr = []
        products.filter(x => {
            return x.name && x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || (x.seller.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        }).forEach((p) => {
                arr.push(products.indexOf(p))
            })
        setSelected(arr)
    }



    const uploadSelected = () => {
        setLoading(true)
        let arr = []
        selected.forEach(index => {
            arr.push(products[index])
        })
        const ALGOLIA_ID = 'G5D1N5084Z';
        const ALGOLIA_ADMIN_KEY = '38148fc0e42c9ade198e18d7fd97b1b5';

        const ALGOLIA_INDEX_NAME = 'products';
        const client = algoliasearch(ALGOLIA_ID, ALGOLIA_ADMIN_KEY);
        arr = arr.map(x => {
            return {
                ...x,
                objectID: x.id
            }
        })
        console.log(arr)
        const index = client.initIndex(ALGOLIA_INDEX_NAME);
        index.saveObjects(arr, (err, content) => {
            console.log(err)
            console.log(content)
        }).then((x) => {
            console.log(x)
            setLoading(false)
            setSuccess(true)
            setMessage("Products added to algolia!")
        })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setError(true)
                setMessage("SOMETHING WENT TERRIBLY WRONG WHEN TRYING TO UPLOAD PRODUCTS TO ALGOLIA")
            })

    }

    return (
        <Container maxWidth={'lg'}>
            <div ref={ref}></div>
            <Grid item container xs={12} spacing={3} justify={'center'} alignContent={'center'} alignItems={'center'}>
            {products.filter(x => {
                return x.name && x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || (x.seller.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            }).
            map((product,index) => {
                if (index < page*30 && index >= (page*30) - 29)
                return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={JSON.stringify(product)}
                style={{border: selected.indexOf(index) !== -1 ? '2px solid black': '2px solid none'}}

                >
                    <ProductCard {...product} scriptProduct/>
                    <Button onClick={() => toggleSelect(index)} fullWidth size={'small' +
                    ''}>Select</Button>
                </Grid>
                )
            })}
            </Grid>
            <Toolbar uploadSelected={uploadSelected} selectAll={selectAll} delete={() => deleteAll()} total={products.filter(x => {
                return x.name && x.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || (x.seller.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            }).length} selected={selected.length} numPages={Math.ceil(products.length / 30)} page={page} setPage={setPage} scrollTop={scrollToTop} setFilter={setFilter} filter={filter}/>
        </Container>
    )
}

const Toolbar = props => {
    return (
            <Paper style={{width: '100vw', position: 'fixed', bottom: 0, left: 0}}>
                <Grid item xs={12} container justify={'center'} spacing={1}>
            
                    <Grid item xs={12} container justify={'center'}>
                        <TextField value={props.filter} onChange={e => props.setFilter(e.target.value)}/>
                    </Grid>

            
                    <Grid item xs={12}>
                        <Typography align={'center'}>
                            Number of Products selected: {props.selected}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align={'center'}>
                            Showing products {(props.page*30)-29} to {props.page*30 > props.total ? props.total:props.page*30} of {props.total} products
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button color='primary' variant='contained' fullWidth onClick={props.selectAll}>Select All</Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button color='primary' variant='contained' fullWidth onClick={props.uploadSelected}>Upload selected to algolia</Button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button color='primary' variant='contained' fullWidth onClick={props.delete}>Delete All</Button>
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent:'center'}}>
                        <Pagination
                            shape={'rounded'}
                            variant={'outlined'}
                            count={props.numPages}
                            size={'large'}
                            page={props.page}
                            onChange={(e, value) => {
                                e.preventDefault()
                                props.setPage(value)
                                props.scrollTop()

                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
    )
}