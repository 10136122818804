import withRoot from './modules/withRoot';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import FormButton from './modules/form/FormButton';
import TextField from "./modules/components/TextField";
import {FirebaseContext, FirebaseDB} from "../firebase/firebase";
import Grid from "@material-ui/core/Grid";
import {HomeContext} from "./Home";
import Button from "@material-ui/core/Button";
import {Checkbox} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.secondary.main,
        '&:hover' : {
            cursor: 'pointer'
        }
    }
}));

function SignUp() {
  const classes = useStyles();
  const [password, setPassword]  = React.useState('');
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [agreed, setAgreed] =  React.useState(false);
  const {setSignOpen, setRegisterOpen} = React.useContext(HomeContext);
  const {signUp, socialSignUp} = React.useContext(FirebaseContext);
  const {setSuccess, setMessage, setError, setLoading, setTermsOpen, setPrivacyOpen} = React.useContext(HomeContext);
  let data = {name, email, created: new Date()}


  const onSubmit = () => {
    setLoading(true)
    signUp(email, password, data)
        .then(res => {
          console.log(res)
          setSuccess(true)
          setMessage("Sign up successful.")
          setLoading(false);
          window.location.href = '/'
        })
        .catch(err => {
          console.log(err)
          setError(true)
          setMessage(`Sign up failed ${err.message}`)
          setLoading(false)
        })
  };
    const socialHandler = (e, type) => {
        e.preventDefault()
        socialSignUp(type)
            .then(res => {
                let name = res.user.displayName ? res.user.displayName: 'No Name'
                let email = res.user.email ? res.user.email : ''
                let photoUrl = res.user.photoUrl ? res.user.photoUrl : ''
                FirebaseDB.collection('users').doc(res.user.uid).update(
                    {
                        name,
                        email,
                        photoUrl
                    }
                )
                    .then(res => {
                        setSuccess(true)
                        window.location.href = '/'
                    })
                    .catch(err => {
                        setMessage("Something went wrong")
                        setError(true)
                    })
            })
            .catch(err => {
                    console.log(err)
                    setError(true)
                    setMessage(err.message? err.message:'Something went wrong. Please try again later')
                }
            )
    }

    return (
      <React.Fragment>
          <React.Fragment>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Sign Up
            </Typography>

            <Grid container justify={'center'} spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body2" align="center">
                        {'Already a member? '}
                        <Link align="center"
                              className={classes.link}
                              onClick={() => {
                                  setSignOpen(true)
                                  setRegisterOpen(false)
                              }}>
                            Sign In here
                        </Link>
                    </Typography>

                </Grid>
                <Grid item container xs={12} spacing={2} alignItems={'center'}>
                    <Grid item xs={12}>
                        <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160512_mobilesdk/auth_service_google.svg'}/>} onClick={e => socialHandler(e, 'google')}>Sign up with Google</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160409_mobilesdk/images/auth_service_facebook.svg'}/>} onClick={e => socialHandler(e, 'facebook')}>Sign up with Facebook</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160409_mobilesdk/images/auth_service_twitter.svg'}/>} onClick={e => socialHandler(e, 'twitter')}>Sign up with Twitter</Button>
                    </Grid>
                </Grid>
              <Grid item xs={12} className={classes.field}>
                <TextField fullWidth label={'Full Name'} value={name} onChange={e => setName(e.target.value)}/>
              </Grid>
              <Grid item xs={12} className={classes.field}>
                <TextField fullWidth label={'Email'} value={email} onChange={e => setEmail(e.target.value)}/>
              </Grid>
              <Grid item xs={12} className={classes.field}>
                <TextField fullWidth type={'password'} label={'Password'} value={password} onChange={e => setPassword(e.target.value)}/>
              </Grid>
                <Grid item xs={12} container justify={'center'} alignItems={'center'}>
                    <Grid item xs={11}>
                        <Typography variant={'caption'}>
                            By creating an account you are agreeing to our <a className={classes.link} onClick={() => setTermsOpen(true)}>Terms of Service</a> and our <a className={classes.link} onClick={() => setPrivacyOpen(true)}>Privacy Policy</a>
                        </Typography>
                    </Grid>

                </Grid>
              <Grid item xs={12}>
                <FormButton color={'secondary'} fullWidth onClick={onSubmit} disabled={password === '' || email === '' || name === ''}>Sign up</FormButton>
              </Grid>

            </Grid>
          </React.Fragment>
      </React.Fragment>
  );
}

export default withRoot(SignUp);
