import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from "@material-ui/core/Slide";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import WhatshotIcon from '@material-ui/icons/Whatshot';
import Typography from "@material-ui/core/Typography";
import {HomeContext} from "./Home";
import {FirebaseDB} from "../firebase/firebase";
import uuidv4 from "uuid/v4";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {

    return <Slide direction={'left'} ref={ref} {...props} timeout={800}/>;
});


export default function ContactDialog(props) {
    const classes = useStyles()
    const [feedback, setFeedback]  = React.useState('')
    const [name, setName] = React.useState('')
    const {setSuccess, setMessage, setError, contactOpen, setContactOpen} = React.useContext(HomeContext)


    const uploadFeedback = () => {

        FirebaseDB.collection('feedback').doc(uuidv4()).set({
            message: feedback,
            name,
            date: new Date()
        })
            .then(res => {
                setSuccess(true)
                setMessage("Thanks for your feedback!")
                setContactOpen(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
            })
    }
    return (
        <Container className={classes.root} component="section">
            <Dialog
                TransitionComponent={Transition}
                open={contactOpen}
                onClose={() => setContactOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogContent>
                    <Grid container justify={'center'} alignItems={'center'} spacing={2}>
                        <Grid item xs={12} container alignItems={'center'} justify={'center'} spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant={'h6'} color={'primary'}>Contact us</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField color='secondary' variant={'outlined'} value={name} onChange={e => setName(e.target.value)} label={'Your name'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField color='secondary' rows={5} rowsMax={7} variant={'outlined'} value={feedback} onChange={e => setFeedback(e.target.value)} label={'How can we help you?'} multiline fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth color={'secondary'} variant={'outlined'} endIcon={<WhatshotIcon/>} onClick={uploadFeedback}>Send contact form</Button>
                        </Grid>

                    </Grid>

                </DialogContent>
            </Dialog>
        </Container>
    );
}
