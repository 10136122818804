import React, {useState} from 'react'
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";

import {connectAutoComplete, SearchBox} from 'react-instantsearch-dom';
import {HomeContext} from './Home'
import {Grid, Typography, Collapse, withStyles} from '@material-ui/core'
import "./modules/components/search/search.css";
import SearchIcon from "../assets/loupe.svg";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const styles = (theme) => ({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(6),
    },
    h5: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(10),
      },
    },
    more: {
      marginTop: theme.spacing(4),
    },
    searchIcon: {
      "&:hover": {
        color: theme.palette.common.white,
        cursor: "pointer",
      },
    },
    search: {
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(8),
      },
      display: 'flex',
      position: 'absolute'

    },
    auto: {
      position: 'absolute',
      top: 60,
      maxWidth: '100%',
      display: 'inline-block',
      height: 600,
      overflowY: 'auto'
      
    },
    autoProduct: {
      margin: theme.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.01)'
      }
    }
  });

const AProduct = props => {
    let {name, picUrl, seller, price, url, classes, search} = props
    const {setProductsOpen, setSearch} = React.useContext(HomeContext)
    return (
        <Paper elevation={5} className={classes.autoProduct} onClick={() => {
            window.open(url, '_blank')
            setProductsOpen(true)
            setSearch(search)
        }}>
            <Grid container item xs={12} alignItems={'center'} alignContent={'center'} spacing={1}>
                <Grid item xs={3}>
                    <img src={picUrl} width={75}/>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant={'h5'} align={'right'} style={{fontSize: 18}}>
                        {name}
                    </Typography>
                    <Typography variant={'body2'} color={'secondary'} align={'right'}>
                        {seller}
                    </Typography>
                    <Typography variant={'h6'} color={'secondary'}  align={'right'}>
                        ${price ? Number(price).toFixed(2):''}
                    </Typography>
                </Grid>

            </Grid>
        </Paper>
        )
}

const AutoProduct = withStyles(styles)(AProduct)

const Search = props => {
    const {classes, hits, refine} = props
    const [showing, setShowing] = React.useState(false)
    const {setSearch, setProductsOpen, setCategory} = React.useContext(HomeContext)
    const [homeSearch, setHomeSearch] = React.useState('')
    const [searchStyle, setSearchStyle] = useState(false);


    const handleSearchOpen = () => {
      setSearchStyle(true);
    };
    const handleSearchClose = () => {
      setSearchStyle(false);
    };
    React.useEffect(() => {
        if (homeSearch !== '') setShowing(true)
        else setShowing(false)
        refine(homeSearch)
    },[homeSearch])




    const submit = e => {
        e.preventDefault()
        setCategory('')
        setSearch(homeSearch)
        setProductsOpen(true)
    }

    return(<>
        <form className={searchStyle ? "search triggered" : "search"} onSubmit={submit}>
          <ClickAwayListener>
            <input
                type="text"
                placeholder="Search athleisure brands, products and styles"
                value={homeSearch}
                onChange={e => {
                    setHomeSearch(e.target.value)
                    setShowing(true)
                }}
            />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={() => setShowing(false)}>
            <div className={classes.auto}>
                {showing && hits.length > 0 &&
                <>
                {hits.map(hit => {
                    return (
                        <AutoProduct key={JSON.stringify(hit)} {...hit} search={homeSearch}/>
                    )
                })}
                </>
                }
            </div>
            </ClickAwayListener>
            <div className="search-icon">
                <img src={SearchIcon} onClick={() => setSearchStyle(!searchStyle)} />
            </div>
        </form>
         
        </>
    )
}

export const CustomSearch = connectAutoComplete(withStyles(styles)(Search))