import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from "@material-ui/core/Slide";
import AppAppBar from "./modules/views/AppAppBar";
import AppFooter from "./modules/views/AppFooter";
import Grid from "@material-ui/core/Grid";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import CardLoop from "./ProductCards";
import UserProfile from "./UserProfile";
import Typography from "@material-ui/core/Typography";
import {HomeContext} from "./Home";
import {FirebaseContext} from "../firebase/firebase";
import ProductForm from "./ProductForm";
import PartnerAdmin from "./PartnerAdmin";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    let num = Math.floor(Math.random() * 4)
    let direction = 'down'
    switch (num) {
        case 1:
            direction = 'up'
            break;
        case 2:
            direction = 'left'
            break;
        case 3:
            direction = 'right'
            break;
        default:
            direction = 'down'
    }
    return <Slide direction={direction} ref={ref} {...props} timeout={800}/>;
});


export default function ProductDialog() {
    const {profileOpen, setProfileOpen} = React.useContext(HomeContext)
    const classes = useStyles()
    const {user} = React.useContext(FirebaseContext)
    const close = () => {
        setProfileOpen(false);
    };

    return (
        <Container className={classes.root} component="section">
            <Dialog
                TransitionComponent={Transition}
                open={profileOpen}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen


            >
                <DialogContent>
                    <AppAppBar/>
                    <Grid item xs={12} style={{position: 'fixed', zIndex: 300}}>
                        <IconButton onClick={() => setProfileOpen(false)} variant={'contained'} color={'secondary'}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Grid>
                    <UserProfile/>

                </DialogContent>
            </Dialog>
        </Container>
    );
}
