import withRoot from './modules/withRoot';
// --- Post bootstrap -----
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import { email, required } from './modules/form/validation';
import FormButton from './modules/form/FormButton';
import {FirebaseContext} from "../firebase/firebase";
import Grid from "@material-ui/core/Grid";
import TextField from "./modules/components/TextField";
import Button from '@material-ui/core/Button'
import {HomeContext} from "./Home";
const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
    link: {
        color: theme.palette.secondary.main,
        '&:hover' : {
            cursor: 'pointer',
        }
    }
}));

function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const {loginUser, socialSignUp} = React.useContext(FirebaseContext)
  const {setError, setMessage, setSuccess, setRegisterOpen, setSignOpen, setLoading} = React.useContext(HomeContext)



  const onSubmit = () => {
    setLoading(true)
    loginUser(email, password)
        .then(res => {
          console.log(res)
          window.location.href = '/'
          setSuccess(true)
          setMessage('Login was successful! Welcome')
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
          setError(true)
          setMessage('Error' + err.message ? err.message: 'Something went wrong')
        })
  };
  const socialHandler = (e, type) => {
    e.preventDefault()
    socialSignUp(type)
        .then(res => {
          console.log(res)
          setSuccess(true)
          window.location.href = '/'
        })
        .catch(err => {
              console.log(err)
              setError(true)
              setMessage(err.message? err.message:'Something went wrong. Please try again later')
            }
        )
  }



  return (
      <React.Fragment>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Grid container justify={'center'} spacing={2}>
              <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                      {"Don't have an account? "}
                      <Link align="center"
                            className={classes.link}
                            onClick={() => {
                                setSignOpen(false)
                                setRegisterOpen(true)
                            }}>
                          Sign up here
                      </Link>
                  </Typography>

              </Grid>
              <Grid item container xs={12} spacing={2} alignItems={'center'}>
                  <Grid item xs={12}>
                      <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160512_mobilesdk/auth_service_google.svg'}/>} onClick={e => socialHandler(e, 'google')}>Sign in with Google</Button>
                  </Grid>
                  <Grid item xs={12}>
                      <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160409_mobilesdk/images/auth_service_facebook.svg'}/>} onClick={e => socialHandler(e, 'facebook')}>Sign in with Facebook</Button>
                  </Grid>
                  <Grid item xs={12}>
                      <Button fullWidth variant={'outlined'} endIcon={<img src={'https://www.gstatic.com/mobilesdk/160409_mobilesdk/images/auth_service_twitter.svg'}/>} onClick={e => socialHandler(e, 'twitter')}>Sign in with Twitter</Button>
                  </Grid>
              </Grid>
            <Grid item xs={12} className={classes.field}>
              <TextField fullWidth label={'Email'} value={email} onChange={e => setEmail(e.target.value)}/>
            </Grid>
            <Grid item xs={12} className={classes.field}>
              <TextField fullWidth type={'password'} label={'Password'} value={password} onChange={e => setPassword(e.target.value)}/>
            </Grid>
            <Grid item xs={12}>
              <FormButton color={'secondary'} fullWidth onClick={onSubmit}>Sign in</FormButton>
            </Grid>
          </Grid>
        </React.Fragment>
      </React.Fragment>
  );
}

export default withRoot(SignIn);
