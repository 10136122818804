import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from "@material-ui/core/Slide";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import SignIn from "./SignIn";
import {HomeContext} from "./Home";
import Terms from "./Terms";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import Privacy from "./Privacy";



const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
    body: {
        backgroundColor: theme.palette.secondary.light,
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    let num = Math.floor(Math.random() * 4)
    let direction = 'down'
    switch (num) {
        case 1:
            direction = 'up'
            break;
        case 2:
            direction = 'left'
            break;
        case 3:
            direction = 'right'
            break;
        default:
            direction = 'down'
    }
    return <Slide direction={direction} ref={ref} {...props} timeout={1200}/>;
});

export default function TermsDialog(props) {
    const classes = useStyles()
    const {termsOpen, setTermsOpen, setPrivacyOpen, privacyOpen} = React.useContext(HomeContext)


    const close = () => {
        setTermsOpen(false);
        setPrivacyOpen(false);
    };

    return (
        <Container className={classes.root} component="section">
            <Dialog
                TransitionComponent={Transition}
                open={termsOpen || privacyOpen}
                onClose={close}
                scroll={'body'}
                fullScreen
            >
                <DialogContent className={classes.body}>
                    {privacyOpen ? <Privacy/>:<Terms/>}
                </DialogContent>
            </Dialog>
        </Container>
    );
}
