import React from "react";
import PropTypes from "prop-types";
import { withStyles, fade } from "@material-ui/core/styles";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import { FirebaseContext } from "../../../firebase/firebase";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { HomeContext } from "../../Home";
import logo from "../../../assets/LoKi_2.png";
import Typography from "../components/Typography";
import SliderMenu from "./SliderMenu/SliderMenu";
import Search from "../components/search/search";
import { IconButton } from "@material-ui/core";
import { WhatshotOutlined } from "@material-ui/icons";
import {CustomSearch} from '../../AutoComplete'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main,
    },
  },
  logo: {
    color: theme.palette.secondary.main,
    "@media only screen and (max-width: 768px)": {
      marginLeft: "-20px",
    },
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "center",
  },
  left: {
    flex: 1,
    justifyContent: "center",
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  link: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    "&:hover": {
      textcolor: theme.palette.secondary,
      cursor: "pointer",
    },
  },
  rightLink: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.common.white,
      cursor: "pointer",
    },
  },
  menu: {
    zIndex: 20,
  },
  profileLink: {
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main,
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

function AppAppBar(props) {
  const { classes } = props;
  const { user, isUserLoading } = React.useContext(FirebaseContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    setRegisterOpen,
    setSignOpen,
    setOpenMenu,
    setSearch,
    setProductsOpen,
    setCategory,
  } = React.useContext(HomeContext);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProductsOpen(true);
  };


  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div>
            <Typography
              variant="h6"
              underline="none"
              color="inherit"
              className={classes.title}
              align={"center"}
              onClick={() => (window.location.href = "/")}
            >
              <img src={logo} width={100} className={classes.logo} />
            </Typography>
          </div>

          <div className={classes.right}>
            <CustomSearch/>
            <div>
              {user && user.roles && user.roles.indexOf('admin') !== -1 ? 
              <IconButton onClick={() => setOpenMenu(true)}>
                <WhatshotOutlined/>
              </IconButton>:<></>}
            </div>
            <div
              style={{ margin: "auto", marginLeft: "20px", marginRight: "0" }}
            >
              <SliderMenu ref={anchorRef} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          ></Grow>
        )}
      </Popper>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
