import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
import Slide from "react-reveal/Slide";
import { HomeContext } from "../../Home";
import SignDialog from "../../SignDialog";
import {Link} from "react-router-dom";
import _ from 'lodash'


const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      //   border: this.props.title == "" ? "none" : '4px solid currentColor',
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
});

function ProductCategories(props) {
  const { classes } = props;

  const images = [
    {
      url: require('../../../assets/hero/6.jpg'),
      title: 'Lounge',
      width: '33.3%',
      link: "",
    },
    {
      url: require('../../../assets/hero/7.jpg'),
      title: 'Active',
      width: '33.3%',
      link: "",
    },
    {
      url: require('../../../assets/hero/3.jpg'),
      title: 'Shoes',
      width: '33.3%',
      link: "",
    },
    {
      url: require('../../../assets/hero/4.jpg'),
      title: 'Tops',
      width: '45%',
      link: "",
    },
    {
      url:  require('../../../assets/hero/5.jpg'),
      title: 'Bottoms',
      width: '25%',
      link: "",
    },
    {
      url: require('../../../assets/hero/2.jpg'),
      title: 'Equipment',
      width: '30%',
      link: "",
    },

  ];

  const { setSearch, setProductsOpen, setCategory } = React.useContext(
    HomeContext
  );

  return (
    <Slide left>
      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <Link
              // to={image.link}
              className={classes.rightLink}
              onClick={() => {
                setProductsOpen(true);
                setCategory(image.title);
                setSearch("");
              }}
            >
              <div
                className={classes.imageSrc}
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
              <div className={classes.imageBackdrop} />
              <div className={classes.imageButton}>
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className={classes.imageTitle}
                >
                  {image.title}
                </Typography>
              </div>
            </Link>
          </ButtonBase>
        ))}
      </div>
    </Slide>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
