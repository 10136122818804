import React,{useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from 'react-select'
import {makeStyles} from "@material-ui/core/styles";
import {FirebaseDB} from '../firebase/firebase'
import Typography from "./modules/components/Typography";
import Button from "./modules/components/Button";
import {HomeContext} from "./Home";
import {ProductCard} from "./ProductCards";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        padding: theme.spacing(2),
        margin: theme.spacing(2),
    }

}))

export default function ProductForm(props) {
    const classes = useStyles()
    const [name, setName] = React.useState('')
    const [desc, setDesc] = React.useState('')
    const [price, setPrice] = React.useState(0)
    const [seller, setSeller] = React.useState('')
    const [url, setUrl] = React.useState('')
    const [picUrl, setPicUrl] = React.useState('')
    const [categories, setCategories] = React.useState([])
    const [disabled, setDisabled] = React.useState(true)
    const [sellerOptions, setSellerOptions] = React.useState([])
    const [isFeatured, setIsFeatured] = React.useState(false)
    const [gender, setGender] = React.useState({})
    const {setLoading, setSuccess, setError, setMessage, partners} = React.useContext(HomeContext)


    const categoryOptions = [
        'Lounge',
        'Active',
        'Shoes',
        'Tops',
        'Bottoms',
        'Equipment',
        'Other'
    ].map(x => {
        return {label: x, value: x}
    })

    React.useEffect(() => {
        let arr = []
        partners.forEach(partner => {
            arr.push({label: partner.name, value: partner.logoUrl})
        })
        setSellerOptions(arr)
        props.name ? setName(props.name) : console.log("No name")
        props.desc ? setDesc(props.desc) : console.log("No desc")
        props.price ? setPrice(props.price) : console.log("No price")
        props.url ? setUrl(props.url) : console.log("No url")
        props.picUrl ? setPicUrl(props.picUrl) : console.log("No picUrl")
        props.category ? setCategories([{label: props.category, value: props.category}]) : console.log("No category")


    }, [])



    React.useEffect(() => {
        setSuccess(false)
        setError(false)
        if (name !== '' && desc !== '' && price !== 0 && seller !== '' && url !== '' && picUrl !== '' && categories !== [])
        {
            setDisabled(false)
        }
        else setDisabled(true)
    }, [name, desc, price, seller, url, picUrl, categories])




    const values = {name, desc, price, seller: seller.label, url, picUrl, categories: categories.map(category => category.value), logoUrl: seller.value, isFeatured, gender: gender.value || 'None'}


    const clear = () => {
        setName('')
        setDesc('')
        setPrice(0)
        setUrl('')
        setPicUrl('')
        setSeller('')
        setCategories([])


    }

    const addProduct = () => {
        setLoading(true)
        FirebaseDB.collection('products').doc(`${name}${seller.label}`).set({
            ...values
        })
            .then(res => {
                console.log(res)
                setSuccess(true)
                setMessage('Product successfully added to the database')
                setLoading(false)
            })
            .catch(err => {
                setError(true)
                setMessage("Something went wrong. We were unable to add the product to the database")
                setLoading(false)
            })
    }

    return(
            <Grid item xs={12} container justify={'center'} alignItems={'center'} spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} align={'center'}>Product Admin</Typography>
                </Grid>
                    <Grid item xs={12} md={6} container justify={'center'}>
                        <Paper className={classes.paper}>
                            <Grid container  justify={'center'} alignItems={'center'} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant={'h4'} align={'center'}>Add a product</Typography>
                        </Grid>
                                <Grid item xs={2}>
                                    <Button size={'small'} color='secondary' onClick={clear}>Clear</Button>
                                </Grid>

                        <Grid item xs={12}>
                            <TextField value={name} onChange={e => setName(e.target.value)} label={'Product Name'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={desc} onChange={e => setDesc(e.target.value)} label={'Product Description'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={price} type='number' onChange={e => setPrice(e.target.value)} label={'Price'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={url} onChange={e => setUrl(e.target.value)} label={'Product link'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={picUrl} onChange={e => setPicUrl(e.target.value)} label={'Product image link'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Select options={sellerOptions} value={seller} onChange={x => setSeller(x)}/>
                        </Grid>
                                <Grid item xs={12}>
                                    <Select options={['Male', 'Female'].map(x => {
                                       return ({label: x, value: x})
                                    })} value={gender} onChange={x => setGender(x)}/>
                                </Grid>
                        <Grid item xs={12}>
                            <Select options={categoryOptions} value={categories} onChange={x => setCategories(x)} isMulti/>
                        </Grid>
                                <Grid item xs={12} container alignItems={'center'}>
                                    <Grid item xs={1}>
                                        <Checkbox checked={isFeatured} onClick={() => setIsFeatured(!isFeatured)}/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Featured</Typography>
                                    </Grid>
                                </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant={'outlined'} onClick={addProduct} disabled={disabled}>Add Product</Button>
                        </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                <Grid item container md={6} justify={'center'}>
                    <Grid item xs={12}>
                        <Typography variant={'h4'} align={'center'}>
                            Product preview
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={8} lg={6} xl={4} container justify={'center'}>
                        <ProductCard {...values}/>
                    </Grid>
                </Grid>
            </Grid>
    )
}
