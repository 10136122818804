import React from "react";
import Grid from "@material-ui/core/Grid";
import { HomeContext } from "./Home";
import { FirebaseDB } from "../firebase/firebase";
import Typography from "@material-ui/core/Typography";
import { ProductCard } from "./ProductCards";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Card } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Pagination from "@material-ui/lab/Pagination";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  grid: {
    "&:hover": {
      cursor: "pointer",
      border: `2px solid ${theme.palette.secondary}'`,
    },
  },
  searchCard: {
    marginBottom: theme.spacing(10),
    zIndex: 30,
    position: "fixed",
    height: 250,
  },
  productSection: {
    marginTop: theme.spacing(30),
  },
}));

export default function MultiAdmin() {
  const { products } = React.useContext(HomeContext);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [numPages, setNumPages] = React.useState(1);
  const [filteredProducts, setFilteredProducts] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);

  const classes = useStyles();
  let range = 30;

  React.useEffect(() => {
    setFilteredProducts(products);
  }, []);

  React.useEffect(() => {
    setNumPages(Math.ceil(filteredProducts.length / 30));
  }, [filteredProducts]);

  React.useEffect(() => {
    let arr = [...products];
    let filterArr = _.filter(
      arr,
      (x) => x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
    setFilteredProducts(filterArr);
    setPage(1);
    setSelected([]);
  }, [search]);

  const toggleSelected = (index) => {
    let arr = [...selected];
    selected.indexOf(index) === -1
      ? arr.push(index)
      : arr.splice(arr.indexOf(index), 1);
    setSelected(arr);
  };
  const handlePage = (e, value) => {
    e.preventDefault();
    setPage(value);
  };

  const selectAll = (e) => {
    e.preventDefault();
    let arr = filteredProducts.map((x, index) => index);
    setSelected(arr);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        container
        item
        xs={12}
        className={classes.searchCard}
        justify={"center"}
      >
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container item xs={12} justify={"center"} spacing={3}>
                <Grid item xs={7}>
                  <TextField
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    label={"Filter Products By Name"}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}>
                  <Button
                    fullWidth
                    onClick={selectAll}
                    variant={"outlined"}
                    color={"primary"}
                  >
                    Select all
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ justifyContent: "center" }}>
              <Tooltip title={"Delete all products"}>
                <IconButton
                  onClick={() => setDeleteOpen(true)}
                  color={"secondary"}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <div className={classes.pagination}>
                <Pagination
                  shape={"rounded"}
                  variant={"outlined"}
                  count={numPages}
                  size={"large"}
                  page={page}
                  onChange={handlePage}
                />
              </div>
              <Tooltip title={"Update all products"}>
                <IconButton
                  onClick={() => setEditOpen(true)}
                  color={"secondary"}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        className={classes.productSection}
        spacing={3}
        justify={"center"}
      >
        {filteredProducts.map((product, index) => {
          if (index < page * range && index >= (page - 1) * range) {
            return (
              <Grid
                className={classes.grid}
                style={{
                  border:
                    selected.indexOf(index) !== -1
                      ? "2px solid black"
                      : "2px solid white",
                }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <ProductCard {...product} />
                <Button
                  fullWidth
                  onClick={() => toggleSelected(index)}
                  variant={"outlined"}
                >
                  {selected.indexOf(index) !== -1 ? "Deselect" : "Select"}
                </Button>
              </Grid>
            );
          }
        })}
      </Grid>
      <DeleteDialog
        open={deleteOpen}
        close={() => setDeleteOpen(false)}
        filteredProducts={filteredProducts}
        selected={selected}
      />
      <EditDialog
        open={editOpen}
        close={() => setEditOpen(false)}
        filteredProducts={filteredProducts}
        selected={selected}
      />
    </Grid>
  );
}

function DeleteDialog(props) {
  const { setSuccess, setLoading, setError, setMessage } = React.useContext(
    HomeContext
  );

  const handleDelete = (e) => {
    e.preventDefault();
    if (props.selected && props.selected.length > 0) {
      setLoading(true);
      let promises = [];
      props.selected.forEach((index) => {
        if (props.filteredProducts[index]) {
          promises.push(
            FirebaseDB.collection("products")
              .doc(
                `${props.filteredProducts[index].name
                  .replace("/", "")
                  .replace("/", "")
                  .replace("/", "")}${props.filteredProducts[index].seller}`
              )
              .delete()
          );
        }
      });

      Promise.all(promises)
        .then((res) => {
          setSuccess(true);
          setMessage("Products Deleted Successfully");
          setLoading(false);
          props.close();
        })
        .catch((err) => {
          setError(true);
          setMessage(
            "Something went wrong when trying to delete products. Please try again"
          );
          setLoading(false);
          props.close();
        });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid item xs={12} container>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to delete {props.length} products?
            </Typography>
          </Grid>
          <Grid item xs={6} onClick={props.close}>
            <Button fullWidth variant={"outlined"}>
              No
            </Button>
          </Grid>
          <Grid item xs={6} onClick={handleDelete}>
            <Button fullWidth variant={"outlined"}>
              Yes
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

function EditDialog(props) {
  const { setSuccess, setLoading, setError, setMessage } = React.useContext(
    HomeContext
  );
  const [attribute, setAttribute] = React.useState("");
  const [attributeValue, setAttributeValue] = React.useState("");

  const handleUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.selected && props.selected.length > 0) {
      let promises = [];
      props.selected.forEach((index) => {
        if (props.filteredProducts[index]) {
          promises.push(
            FirebaseDB.collection("products")
              .doc(
                `${props.filteredProducts[index].name
                  .replace("/", "")
                  .replace("/", "")
                  .replace("/", "")}${props.filteredProducts[index].seller}`
              )
              .update({
                [`${attribute.toLowerCase()}`]: attributeValue,
              })
          );
        }
      });

      Promise.all(promises)
        .then((res) => {
          setSuccess(true);
          setMessage("Products Updated Successfully :D");
          setLoading(false);
          props.close();
        })
        .catch((err) => {
          setError(true);
          setMessage(
            "Something went wrong when trying to delete products. Please try again"
          );
          setLoading(false);
          props.close();
        });
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Add an attribute to {props.selected.length} products
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={attribute}
              onChange={(e) => setAttribute(e.target.value)}
              fullWidth
              variant={"outlined"}
              label={"Key"}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={attributeValue}
              onChange={(e) => setAttributeValue(e.target.value)}
              fullWidth
              variant={"outlined"}
              label={"Value"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant={"outlined"}
              color={"primary"}
              onClick={handleUpdate}
            >
              Update Products
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
