import React from "react";
import PropTypes from "prop-types";
import { withStyles, fade } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";
import { FirebaseContext } from "../../../firebase/firebase";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { HomeContext } from "../../Home";
import logo from "../../../assets/LoKi_2.png";
import Typography from "../components/Typography";
import SliderMenu from "./SliderMenu/SliderMenu";
import Search from "../components/search/search";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.secondary.main,
    },
  },
  logo: {
    color: theme.palette.secondary.main,
    "@media only screen and (max-width: 768px)": {
      marginLeft: "-20px",
    },
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "center",
  },
  left: {
    flex: 1,
    justifyContent: "center",
  },

  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },

  menu: {
    zIndex: 20,
  },
});

function AppAppBar(props, { dialogContent }) {
  const { classes } = props;
  const { user, isUserLoading } = React.useContext(FirebaseContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {
    setRegisterOpen,
    setSignOpen,
    setOpenMenu,
    setSearch,
    setProductsOpen,
    setCategory,
  } = React.useContext(HomeContext);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProductsOpen(true);
  };
  return (
    <div className={classes.root}>
      <AppBar style={{ overflow: "hidden" }} position="absolute">
        <Toolbar className={classes.toolbar}>
          <div>
            <Typography
              variant="h6"
              underline="none"
              color="inherit"
              className={classes.title}
              align={"center"}
              onClick={() => (window.location.href = "/")}
            >
              <img src={logo} width={100} className={classes.logo} />
            </Typography>
          </div>

          <div className={classes.right}>
            <div
              style={{ margin: "auto", marginLeft: "20px", marginRight: "0" }}
            >
              <SliderMenu dialogContent ref={anchorRef} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {/* {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          ></Grow>
        )} */}
      </Popper>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
