import XLSX from 'xlsx'


export default function Cj(workbook) {
    return new Promise((resolve, reject) => {
        console.log(workbook)
        let sheet = workbook.Sheets[workbook.SheetNames[0]]
        let data = XLSX.utils.sheet_to_json(sheet, {header:0})
        resolve(data.map(product => {
            return {
                brand: product['Brand'] || '',
                name: product['Name'] || '',
                picUrl: product['ImageURL'] || '',
                price: product['FinalPrice'] || '',
                desc: product['Description'] || '',
                seller: product['Manufacturer'] || '',
                url: product['LinkUrl'] || ''
            }
        }))
    })

}