import React from "react";
import { Router } from "react-router-dom";
import queryString from "query-string";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import {InstantSearch} from 'react-instantsearch-dom';
import algoliasearch from "algoliasearch/lite";
import withRoot from "./modules/withRoot";
import ProductSmokingHero from "./modules/views/ProductSmokingHero";
import AppFooter from "./modules/views/AppFooter";
import ProductHowItWorks from "./modules/views/ProductHowItWorks";
import ProductCTA from "./modules/views/ProductCTA";
import AppAppBar from "./modules/views/AppAppBar";
import PartnerCarousel from "./modules/views/PartnerCarousel";
import ProfileDialog from "./ProfileDialog";
import LoadingDialog from "./LoadingDialog";
import Snackbar from "./modules/components/Snackbar";
import { FirebaseDB } from "../firebase/firebase";
import TermsDialog from "./TermsDialog";
import SidebarList from "./SidebarList";
import AdminDialog from "./AdminDialog";
import AboutUsDialog from "./AboutUsDialog";
import FeaturedProducts from "./modules/views/FeaturedProducts";
import history from "./modules/history";
import HeroComponent from "./modules/views/Hero";
import ProductDialog from "./ProductDialog";
import SignDialog from './SignDialog'
export let HomeContext = React.createContext({});

function Index() {
  const [productsOpen, setProductsOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [signOpen, setSignOpen] = React.useState(false);
  const [registerOpen, setRegisterOpen] = React.useState(false);
  const [contactOpen, setContactOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [privacyOpen, setPrivacyOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [partners, setPartners] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [featuredProducts, setFeaturedProducts] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [adminOpen, setAdminOpen] = React.useState(false);
  const [adminComponent, setAdminComponent] = React.useState(<></>);
  const [aboutUsOpen, setAboutUsOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  React.useEffect(() => {
    FirebaseDB.collection("partners")
      .get()
      .then((partners) => {
        let arr = [];
        partners.forEach((partnerDoc) => {
          arr.push({
            name: partnerDoc.id,
            logoUrl: partnerDoc.data().logoUrl,
            url: partnerDoc.data().url,
            api: partnerDoc.data().api,
            id: partnerDoc.data().id,
          });
        });
        return arr;
      })
      .then((arr) => setPartners(arr))
      .catch((err) => {
        console.log("Something went terribly wrong");
        setLoading(false);
      });
    FirebaseDB.collection("products")
      .get()
      .then((productDocs) => {
        let arr = [];
        productDocs.forEach((product) => {
          arr.push(product.data());
        });
        return arr;
      })
      .then((arr) => {
        setProducts(arr);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    FirebaseDB.collection("users")
      .doc("9SCSB0WjSdUQ2wmeBLamVzkDRkx2")
      .collection("wishlist")
      .get()
      .then((res) => {
        console.log(res);
        let arr = [];
        res.forEach((product) => {
          console.log(product.data());
          arr.push(product.data());
        });
        return arr;
      })
      .then((arr) => {
        FirebaseDB.collection("users")
          .doc("gGFzrWoUCAhajWW10nT9JrvszjG3")
          .collection("wishlist")
          .get()
          .then((res) => {
            res.forEach((product) => {
              arr.push(product.data());
            });
            return arr;
          })
          .then((arr) => {
            setFeaturedProducts(arr);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    const { search, category, open } = queryString.parse(
      window.location.search
    );
    console.log(search, category, productsOpen);
    if (search) setSearch(search);
    if (category) setCategory(category);
    if (open) setProductsOpen(true);
  }, []);

  React.useEffect(() => {
    let string = "?";
    if (productsOpen) {
      if (category !== "") {
        string = string + `category=${category}&`;
      }
      if (search !== "") {
        string = string + `search=${search}&`;
      }
      string = string + `open=${true}&`;
    }
    if (string !== "?") history.push(string);
    else history.push("");
  }, [category, productsOpen]);

  let values = {
    partners,
    productsOpen,
    setProductsOpen,
    profileOpen,
    setProfileOpen,
    signOpen,
    setSignOpen,
    registerOpen,
    setRegisterOpen,
    contactOpen,
    setContactOpen,
    success,
    setSuccess,
    error,
    setError,
    products,
    message,
    setMessage,
    loading,
    setLoading,
    category,
    setCategory,
    search,
    setSearch,
    termsOpen,
    setTermsOpen,
    openMenu,
    setOpenMenu,
    adminOpen,
    setAdminOpen,
    setAdminComponent,
    adminComponent,
    aboutUsOpen,
    setAboutUsOpen,
    featuredProducts,
    setFeaturedProducts,
    privacyOpen,
    setPrivacyOpen,
  };

  const searchClient = algoliasearch(
    'G5D1N5084Z',
    'dd0f1f4aaed4b89a5ad255214782f99f'
);
  return (
    <Router history={history}>
      <HomeContext.Provider value={values}>
        <InstantSearch searchClient={searchClient} indexName={'products'}>
        <React.Fragment>
          <AppAppBar />
          {/* <LoadingDialog/> */}
          <HeroComponent />
          <FeaturedProducts />
          <ProductHowItWorks />
          <ProductCTA />
          <ProductSmokingHero />
          <Divider />
          <Snackbar
            open={success}
            onClose={() => {
              setSuccess(false);
            }}
            message={message}
          />
          <PartnerCarousel />
          <Snackbar
            open={error}
            onClose={() => {
              setError(false);
            }}
            message={message}
          />
          <div style={{position: 'fixed'}}>
            <ProfileDialog />
            <TermsDialog />
            <ProductDialog />
            <AdminDialog
              open={adminOpen}
              close={() => setAdminOpen(false)}
              component={adminComponent}
            />
            <AboutUsDialog />
            <SignDialog/>
          </div>
          <SwipeableDrawer
            anchor={"right"}
            onClose={() => setOpenMenu(false)}
            onOpen={() => setOpenMenu(true)}
            open={openMenu}
          >
            <SidebarList />
          </SwipeableDrawer>
          <AppFooter />
        </React.Fragment>
        </InstantSearch>
      </HomeContext.Provider>
    </Router>
  );
}

export default withRoot(Index);
