import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import {HomeContext} from "./Home";
import CircularProgress from "@material-ui/core/CircularProgress";
import {FirebaseContext} from "../firebase/firebase";


const useStyles = makeStyles(theme => ({
    content: {
        color: theme.palette.secondary.main

    }
}));


export default function LoadingDialog(props) {
    const classes = useStyles()
    const { loading } = React.useContext(HomeContext)
    const { isUserLoading } = React.useContext(FirebaseContext)

    return (
        <Container className={classes.root} component="section">
            <Dialog
                open={loading || isUserLoading}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'lg'}
            >
                <DialogContent className={classes.content}>
                    <CircularProgress thickness={5} color={'secondary'}/>
                </DialogContent>
            </Dialog>
        </Container>
    );
}
