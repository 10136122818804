import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AppAppBarDialog from "./modules/views/AppAppBarDialog";
import AppAppBar from "./modules/views/AppAppBar";
import AppFooter from "./modules/views/AppFooter";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Container } from "@material-ui/core";
import SearchPage from "./SearchPage";
import Typography from "@material-ui/core/Typography";
import { HomeContext } from "./Home";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: "4px solid currentColor",
    borderRadius: 0,
    height: "auto",
    padding: theme.spacing(2, 5),
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  buoy: {
    width: 60,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  let num = Math.floor(Math.random() * 4);
  let direction = "down";
  switch (num) {
    case 1:
      direction = "up";
      break;
    case 2:
      direction = "left";
      break;
    case 3:
      direction = "right";
      break;
    default:
      direction = "down";
  }
  return <Slide direction={direction} ref={ref} {...props} timeout={1200} />;
});

export default function ProductDialog(props) {
  const { productsOpen, setProductsOpen } = React.useContext(HomeContext);
  const classes = useStyles();
  const close = () => {
    setProductsOpen(false);
  };
  const ref = React.createRef();

  const scrollToTop = () => {
    if (ref && ref.current) {
      ref.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <Container className={classes.root} component="section">
      <Dialog
        TransitionComponent={Transition}
        open={productsOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
        keepMounted={false}
      >
        <DialogContent ref={ref}>
          {/* <AppAppBar dialogContent /> */}
          <AppAppBarDialog />
          <Grid item xs={12} style={{ position: "fixed", zIndex: 300 }}>
            <IconButton
              onClick={() => {
                setProductsOpen(false);
              }}
              variant={"contained"}
              color={"secondary"}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Container maxWidth={"xl"}>
            <SearchPage scrollToTop={scrollToTop} />
            <Copyright />
          </Container>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href=".">
        LoKi {new Date().getFullYear()}
      </Link>
    </React.Fragment>
  );
}
