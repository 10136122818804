import React,{useState, useEffect} from 'react'
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Select from 'react-select'
import {makeStyles , useTheme} from "@material-ui/core/styles";
import {FirebaseDB} from '../firebase/firebase'
import Typography from "./modules/components/Typography";
import Button from "./modules/components/Button";
import {HomeContext} from "./Home";
import {ProductCard} from "./ProductCards";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
const useStyles = makeStyles(theme => ({
    paper: {
        width: '100%',
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    }

}))

export default function PartnerAdmin(props) {
    const classes = useStyles()
    const [name, setName] = React.useState('')
    const [url, setUrl] = React.useState('')
    const [logoUrl, setLogoUrl] = React.useState('')
    const [api, setApi] = React.useState('')
    const [id, setId] = React.useState('')
    const [categories, setCategories] = React.useState([])
    const [disabled, setDisabled] = React.useState(true)
    const [search, setSearch] = React.useState('')
    const {setLoading, setSuccess, setError, setMessage, partners} = React.useContext(HomeContext)
    const partnerRef = React.createRef();
    const theme = useTheme();
    const categoryOptions = [
        {label: 'Apparel', value: 'Apparel'},
        {label: 'Electronics', value: 'Electronics'},
        {label: 'Shoes', value: 'Shoes'},
        {label: 'Pets', value: 'Pets'},
        {label: 'Books', value: 'Books'},
        {label: 'Appliances', value: 'Appliances'},
        {label: 'Home & Garden', value: 'Home & Garden'},
        {label: 'Fitness', value: 'Fitness'},
        {label: 'Beauty', value: 'Beauty'},
        {label: 'Other', value: 'Other'},

    ]


    const apiOptions = [
        {label: 'Amazon', value: 'Amazon'},
        {label: 'Rakuten', value: 'Rakuten'},
        {label: 'Impact Radius', value: 'Impact'},
        {label: 'CJ', value: 'CJ'},
        {label: 'Share a sale', value: 'ShareASale'},
        {label: 'Flex Offers', value: 'Flex Offers'},
        {label: 'Other', value: 'Other'},


    ]

    React.useEffect(() => {
        setSuccess(false)
        if (name !== '' && api !== '' && url !== '' && logoUrl !== '' && categories !== [])
        {
            setDisabled(false)
        }
        else setDisabled(true)
    }, [name, url, logoUrl,api, categories])




    const values = {name, url, categories: categories.map(category => category.value), logoUrl, api: api.label, id}


    const clear = () => {
        setName('')
        setUrl('')
        setLogoUrl(0)
        setApi('')
        setCategories([])
    }

    const addPartner = () => {
        setLoading(true)
        FirebaseDB.collection('partners').doc(name).set({
            ...values
        })
            .then(res => {
                console.log(res)
                setSuccess(true)
                setMessage('Partner has successfully been added to the database')
                setLoading(false)
            })
            .catch(err => {
                setError(true)
                setMessage("Something went wrong. We were unable to add the product to the database")
                setLoading(false)
            })
    }



    const editPartner = partner => {
        setName(partner.name ? partner.name: '')
        setUrl(partner.url ? partner.url : '')
        setLogoUrl(partner.logoUrl ? partner.logoUrl : '')
        setApi(partner.api ? partner.api:'')
        setCategories(partner.categories ? partner.categories: [])
        setId(partner.id ? partner.id: '')
    }



    const deletePartner = name => {
        setLoading(true)
        FirebaseDB.collection('partners').doc(name).delete()
            .then(res => {
                console.log(res)
                setSuccess(true)
                setMessage("Partner has been deleted")
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setError(true)
                setMessage("Something went wrong")
                setLoading(false)
            })
    }

    return(
        <Grid item xs={12} container justify={'center'} alignItems={'center'} spacing={3}>
            <Grid item xs={12}>
                <Typography variant={'h4'} align={'center'}>Partner Admin</Typography>
            </Grid>
            <Grid item xs={12} md={6} container>
                <Paper className={classes.paper}>
                    <Grid container  justify={'center'} alignItems={'center'} spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant={'h4'} align={'center'}>Add partner</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button size={'small'} color='secondary' onClick={clear} fullWidth>Clear</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField value={name} onChange={e => setName(e.target.value)} label={"Partner's Name"} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={url} onChange={e => setUrl(e.target.value)} label={'Website link'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={logoUrl} onChange={e => setLogoUrl(e.target.value)} label={'Partner logo url'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Select options={categoryOptions} value={categories} onChange={x => setCategories(x)} isMulti placeholder={'Select categories'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Select options={apiOptions} value={api} onChange={x => setApi(x)} placeholder={'Select corresponding API'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField value={id} onChange={e => setId(e.target.value)} label={'Partner ID'} variant={'outlined'} fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth variant={'outlined'} onClick={addPartner} disabled={disabled}>Add Partner</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item container xs={12} md={6} justify={'center'}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} align={'center'}>
                       Logo Preview
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6} container justify={'center'}>
                    <ProductCard name={name} logoUrl={logoUrl} picUrl={logoUrl}/>
                </Grid>
            </Grid>
            <Grid item container xs={12} justify='center' spacing={3}>
                <Grid item xs={12}>
                    <Typography variant={'h4'} align={'center'}>Our partners</Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField label={'Filter partners'} variant={'outlined'} value={search} onChange={e => setSearch(e.target.value)} fullWidth/>
                </Grid>
                {partners.filter(partner => partner.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    .map(partner => {

                    return(
                        <Grid item container xs={12} md={3} onClick={() => editPartner(partner)} style={{backgroundColor: partner.id ? `${theme.palette.secondary.main}`:'white'}}>
                        <ProductCard name={partner.name} picUrl={partner.logoUrl} logoUrl={partner.logoUrl} partnerDisplay
                        actions={[
                            {icon: <DeleteOutlineOutlinedIcon/>, func: () => deletePartner(partner.name)},
                            {icon: <EditOutlinedIcon/>, func: () => editPartner(partner.name)}
                        ]}

                        />
                        </Grid>)
                })}
            </Grid>

        </Grid>
    )
}
