import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import Slide from "react-reveal/Slide";
import { HomeContext } from "../../Home";
import { FirebaseContext } from "../../../firebase/firebase";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import ProfileDialog from "../../ProfileDialog";
import HowitWorksImage1 from "../../../assets/hero/8.jpg";
import HowitWorksImage2 from "../../../assets/hero/1.jpg";
import HowitWorksImage3 from "../../../assets/hero/9.jpg";
const styles = (theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light,
    overflow: "hidden",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 200,
    width: "120%",
    objectFit: "cover",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: 20,
    "&:hover": {
      opacity: 0.8,
    },
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
});

function ProductHowItWorks(props) {
  const { classes } = props;
  const { setRegisterOpen, setProfileOpen } = React.useContext(HomeContext);
  const { user } = React.useContext(FirebaseContext);
  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
        >
          How we work
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Slide left>
                <div className={classes.item}>
                  <img
                    // src="https://images.unsplash.com/photo-1544027993-37dbfe43562a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                    src={HowitWorksImage1}
                    alt="suitcase"
                    className={classes.image}
                  />
                </div>
              </Slide>
            </Grid>
            <Grid item xs={12} md={4}>
              <Slide bottom>
                <div className={classes.item}>
                  <img
                    // src="https://images.unsplash.com/flagged/photo-1559733404-9b79677fc959?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                    src={HowitWorksImage2}
                    alt="graph"
                    className={classes.image}
                  />
                </div>
              </Slide>
            </Grid>
            <Grid item xs={12} md={4}>
              <Slide right>
                <div className={classes.item}>
                  <img
                    // src="https://images.unsplash.com/photo-1516570161787-2fd917215a3d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                    src={HowitWorksImage3}
                    alt="clock"
                    className={classes.image}
                  />
                </div>
              </Slide>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}

ProductHowItWorks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHowItWorks);
