export const Links = [
  {
    name: "Lounge",
    items: [
      "Daily Sleeper",
      "Tribe Kelley",
      "Lunya",
      "Aviator Nation",
      "Oyun Studio",
      "Leimere",
      "LHLL",
    ],
    value: true,
  },
  {
    name: "Active",
    items: ["Collections", "FootWear", "Socks", "Skate", "Accessories"],
    value: true,
  },
  {
    name: "Tops",
    items: [
      "shirts",
      "T-shirts",
      "Jackets",
      "Suits",
      "Sweaters",
      "Tank",
      "Cami",
    ],
    value: true,
  },
  {
    name: "Bottoms",
    items: [
      "Jeans",
      "Dress pants",
      "Harem pants",
      "Baggy pants",
      "Flared pants",
      "jumpsuits",
      "Culottes",
    ],
    value: true,
  },
  {
    name: "Shoes",
    items: [
      "Athletic shoes",
      "Army boots",
      "Deck shoes",
      "Golf shoes",
      "Dress shoes",
      "clogs",
      "cowboy boots",
    ],
    value: true,
  },
  {
    name: "Equipment",
    items: [
      "PCs",
      "TVs",
      "Machines",
      "Electronics",
      "Smart phones",
      "Furniture",
    ],
    value: true,
  },
];

export const NewLinks = [
  
  { name: "Active", value: true },
  { name: "Lounge" , value: true},
  { name: "Tops" , value: true},
  { name: "Bottoms" , value: true},
  { name: "Shoes", value: true },
  { name: "Equipment", value: true }
];
