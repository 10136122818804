import React from 'react'
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import {FirebaseContext} from "../firebase/firebase";
import {makeStyles} from "@material-ui/core/styles";
import {HomeContext} from "./Home";
import ProductAdder from "./ProductAdder";
import ProductForm from "./ProductForm";
import PartnerAdmin from "./PartnerAdmin";
import QueueIcon from '@material-ui/icons/Queue';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MultiAdmin from "./MultiAdmin";
import ScriptProductManager from './ScriptProductManager';
import MoneyIcon from '@material-ui/icons/Money';
import FileUpload from './FileUpload'
const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    item: {
        '&:hover': {
            color: theme.palette.secondary.main
        }
    }
}))

export default function SidebarList(props) {
    const classes = useStyles()
    const {user, logout} = React.useContext(FirebaseContext)
    const {setProfileOpen, setAdminOpen, setAdminComponent, setAboutUsOpen} = React.useContext(HomeContext)


    const navItems = [
        {name: 'Wishlist', onClick: () => setProfileOpen(true), icon: <WhatshotOutlinedIcon color={'primary'}/>},
        {name: 'API Product Adder', onClick: () => {
                setAdminComponent(<ProductAdder/>)
                setAdminOpen(true)
            }, icon: <QueueIcon color={'primary'}/>, admin: true},
        {name: 'Single Product Adder', onClick: () => {
                setAdminOpen(true)
                setAdminComponent(<ProductForm/>)
            }, icon: <PostAddIcon color={'primary'}/>, admin: true},
        {name: 'Partner Manager', onClick: () => {
                setAdminOpen(true)
                setAdminComponent(<PartnerAdmin/>)
            }, icon: <GroupAddIcon color={'primary'}/>, admin: true},
            
        {name: 'File Upload', onClick: () => {
                setAdminOpen(true)
                setAdminComponent(<FileUpload/>)
            }, icon: <MoneyIcon color={'primary'}/>, admin: true},
            {name: 'Script Product Manager', onClick: () => {
                setAdminOpen(true)
                setAdminComponent(<ScriptProductManager/>)
            }, icon: <MoneyIcon color={'secondary'}/>, admin: true},


        {name: 'About Us', onClick: () => setAboutUsOpen(true), icon: <InfoIcon color={'primary'}/>},
        {name: 'Sign Out', onClick: (e) => logout(e), icon: <ExitToAppIcon color={'primary'}/>},

    ]

    return(
        <List className={classes.list}>
            {navItems.map((item, index) => {
                if (!item.admin || (user && user.roles && user.roles.indexOf('admin') !== -1)) {
                    return (
                        <>
                            <ListItem button key={item.name} onClick={item.onClick} className={classes.item}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText color={'secondary'} primary={item.name}/>
                            </ListItem>
                            <Divider/>
                        </>
                    )
                }
            })}
        </List>
    )
}
