import React from "react";
import "./SliderMenu.css";
import MenuIcon from "@material-ui/icons/Menu";
import { HomeContext } from "../../../Home";
import { FirebaseContext } from "../../../../firebase/firebase";
import { Link } from "react-router-dom";
import WhatshotOutlinedIcon from "@material-ui/icons/WhatshotOutlined";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Links, NestedLinks, NewLinks } from "./LinksData";
import { findIndex } from "lodash";
const SliderMenu = ({ ref }) => {
  const {
    setRegisterOpen,
    setSignOpen,
    setOpenMenu,
    setSearch,
    setProductsOpen,
    setCategory,
  } = React.useContext(HomeContext);
  const anchorRef = React.useRef(null);
  const [sidebar, setSidebar] = React.useState(false);
  const { user, isUserLoading } = React.useContext(FirebaseContext);
  const [state1, setstate1] = React.useState("");
  const [state2, setstate2] = React.useState("pre-hide-right");
  const [state4, setstate4] = React.useState("");
  const [state5, setstate5] = React.useState("pre-hide-right");
  const [state6, setstate6] = React.useState([]);
  const [state3, setstate3] = React.useState([]);
  const showSidebar = () => {
    setSidebar(!sidebar);
    setstate1("");
    setstate2("pre-hide-right");
    setstate4("");
    setstate5("pre-hide-right");
  };
  const handelsetstate1 = (items) => {
    setstate1("pre-hide-left");
    setstate2("");
    setstate3(items);
  };
  const handelsetstate2 = () => {
    setstate1("");
    setstate2("pre-hide-right");
  };
  const handelsetstate4 = (subs) => {
    setstate4("pre-hide-left");
    setstate5("");
    setstate6(subs);
  };
  const handelsetstate5 = () => {
    setstate4("");
    setstate5("pre-hide-right");
  };
  return (
    <>
      <div className="slider-navbar">
        <span className="slider-menu-icon" onClick={showSidebar}>
          <MenuIcon />
        </span>
      </div>
      <nav className={sidebar ? "slider-menu active" : "slider-menu"}>
        <span onClick={showSidebar} className="menu-x">
          🗙
        </span>
        <div className={`pre-panel ${state1}`}>
          <div className="sign-in-up-user">
            {!isUserLoading && !user ? (
              <>
                <Link color="inherit" onClick={() => setSignOpen(true)}>
                  {"Sign In"}
                </Link>
                <Link
                  onClick={() => setRegisterOpen(true)}
                  className="sign-up-link"
                >
                  {"Sign Up"}
                </Link>
              </>
            ) : (
              <Link ref={anchorRef} onClick={() => setOpenMenu(true)}>
                <>
                  {user && (
                    <IconButton size="small">
                      <WhatshotOutlinedIcon />
                    </IconButton>
                  )}
                </>
              </Link>
            )}
          </div>
          <Link>Wishlist</Link>
          {NewLinks.map((item, indx) => (
            <Link key={indx}>
              <div
                onClick={() => {
                  setProductsOpen(true);
                  setCategory(item.name);
                  setSearch("");
                }}
              >
                {item.name}
              </div>
              {item?.items?.length > 0 && (
                <ArrowForwardIosIcon
                  onClick={() => handelsetstate1(item.items)}
                />
              )}
            </Link>
          ))}
        </div>
        <div className={`pre-panel ${state2}${state4}`}>
          <div className="back-slide">
            <ArrowBackIosIcon onClick={handelsetstate2} />
            <span>All</span>
          </div>
          <div className="nested-links-container">
            {state3.map((item, indx) => (
              <Link key={indx} className="nested-links">
                <div
                  onClick={() => {
                    setProductsOpen(true);
                    setCategory(item.name);
                    setSearch("");
                  }}
                >
                  {item.name}
                </div>
                {item?.subs?.length > 0 && (
                  <ArrowForwardIosIcon
                    onClick={() => handelsetstate4(item.subs)}
                  />
                )}
              </Link>
            ))}
          </div>
        </div>
        <div className={`pre-panel ${state5}`}>
          <div className="back-slide">
            <ArrowBackIosIcon onClick={handelsetstate5} />
            <span>All</span>
          </div>
          <div className="nested-links-container">
            {state6.map((item, indx) => (
              <Link key={indx} className="nested-links">
                <div
                  onClick={() => {
                    setProductsOpen(true);
                    setCategory(item.name);
                    setSearch("");
                  }}
                >
                  {item.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </nav>
    </>
  );
};

export default SliderMenu;
