import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from "@material-ui/core/Slide";
import AppAppBar from "./modules/views/AppAppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Container} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(9),
    },
    button: {
        border: '4px solid currentColor',
        borderRadius: 0,
        height: 'auto',
        padding: theme.spacing(2, 5),
    },
    link: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    buoy: {
        width: 60,
    },
    content: {
        backgroundColor: theme.palette.secondary.light
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    let num = Math.floor(Math.random() * 4)
    let direction = 'down'
    switch (num) {
        case 1:
            direction = 'up'
            break;
        case 2:
            direction = 'left'
            break;
        case 3:
            direction = 'right'
            break;
        default:
            direction = 'down'
    }
    return <Slide direction={direction} ref={ref} {...props} timeout={800}/>;
});


export default function AdminDialog(props) {
    const classes = useStyles()

    return (
        <Container className={classes.root} component="section">
            <Dialog
                TransitionComponent={Transition}
                open={props.open}
                onClose={props.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen

            >
                <DialogContent className={classes.content}>
                    <AppAppBar/>
                    <Grid item xs={12} style={{position: 'fixed', zIndex: 300}}>
                        <IconButton onClick={props.close} variant={'contained'} color={'secondary'}>
                            <ArrowBackIcon/>
                        </IconButton>
                    </Grid>
                    {props.component}
                </DialogContent>
            </Dialog>
        </Container>
    );
}
