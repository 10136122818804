import React from 'react';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import {HomeContext} from "./Home";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import {ProductCard} from "./ProductCards";
import Divider from "@material-ui/core/Divider";
import {Checkbox} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Pagination from "@material-ui/lab/Pagination";

import {
    InstantSearch,
    ScrollTo,
    Configure,
    connectStats,
    connectPagination,
    connectHits,
    connectSearchBox,
    connectRefinementList,
    connectSortBy
} from 'react-instantsearch-dom';


import algoliasearch from "algoliasearch/lite";


const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    sidebar: {
        width: 300,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(0),
        margin: theme.spacing(0)
    },
    button: {
        padding: theme.spacing(2),

    },
    pagination: {
        '& > *': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        }
    },
    margin: {
        margin: theme.spacing(2),
    },
    searchIcon: {
        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        }
    },
    container: {
        minHeight: 700,

    },
    filter: {
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        }
    }
}))


const searchClient = algoliasearch(
    'G5D1N5084Z',
    'dd0f1f4aaed4b89a5ad255214782f99f'
);


export default function SearchPage() {

    const {search, category, setSearch, homeSearch} = React.useContext(HomeContext)

    const [open, setOpen] = React.useState(false)
    const classes = useStyles()

    React.useEffect(() => {
        if (homeSearch !== search) {
            setSearch(homeSearch)
        }
    },[])


    return(
        <InstantSearch searchClient={searchClient} indexName={'products'}>
            <ScrollTo>
                <Configure hitsPerPage={30} analytics={true}/>
            </ScrollTo>
        <Grid item container xs={12} justify={'center'} alignItems={'flex-start'} spacing={2}>
            <Grid item container md={10} xs={12} sm={8} spacing={2} justify={'flex-end'}>
                <Grid item xs={12} container justify={'flex-end'} alignItems={'center'} alignContent={'center'} style={{paddingTop: 50}}>
                    <Grid item md={4} xs={12} sm={12}>
                        <CustomSearch searchValue={search !== '' ? search:false}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    {category !== '' && <Typography variant={'h4'}>{category}</Typography>}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                               <NewSortBy items={[
                                   {value: 'products', label: 'Featured'},
                                   {value: 'Price (High to Low)', label:'Price (High to Low)'},
                                   {value: 'Price (Low to High)', label:'Price (Low to High)'},

                ]} defaultRefinement={'products'}/>
                </Grid>
                <Grid item xs={12} sm={8} md={10}>
                    <Stats/>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Typography color={'secondary'} variant={'h6'} className={classes.filter} align={'right'} onClick={() => setOpen(true)}>
                        Filters
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container spacing={2} justify={'center'} alignContent={'center'} alignItems={'center'}>
                    <CustomHits/>
                </Grid>
            </Grid>
            <Grid item xs={12} container justify={'center'}>
                <div className={classes.pagination}>
                    <NewPagination/>
                </div>
            </Grid>
            <SwipeableDrawer onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} keepMounted className={classes.sidebar}>
            <Grid container spacing={1} alignItems={'center'} justify={'center'} className={classes.sidebar}>
                <Grid item xs={12}>
                    <Typography variant={'h6'} color={'secondary'} align={'center'}>Gender</Typography>
                    <RefineCustom attribute={'gender'}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} color={'secondary'} align={'center'}>Seller</Typography>
                    <RefineCustom attribute={'seller'}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} color={'secondary'} align={'center'}>Category</Typography>
                    <RefineCustom attribute={'categories'} defaultRefinement={category}/>
                </Grid>
                
            </Grid>
            </SwipeableDrawer>
        </Grid>
        </InstantSearch>
    )
}


const Hit = ({ hits, actions }) => {
    console.log(hits)
    return (
        <Grid item container xs={12} spacing={3}>
            {hits.map(hit => {
                return (
                    <Grid item xs={12} sm={6} md={4}>
                        <ProductCard {...hit} actions={actions}/>
                    </Grid>
                )
            })}
        </Grid>
    );
}
const CustomHits = connectHits(Hit)



const Search = ({searchValue, currentRefinement, refine}) => {
    const {setSearch, search} = React.useContext(HomeContext)

    React.useEffect(() => {
        refine(searchValue)
    },[]);

    const classes = useStyles()
        return(    <TextField type={'search'}
               value={search}
               onChange={e => {
                   setSearch(e.target.value)
                   refine(e.target.value)
               }}
               InputProps={{endAdornment: (
            <InputAdornment className={classes.searchIcon}>
                <SearchIcon/>
            </InputAdornment>
        ),
    }}
               variant={'outlined'}
               fullWidth
               color={'secondary'}/>
        )
}
const CustomSearch = connectSearchBox(Search)


const CustomPagination = ({ currentRefinement, nbPages, refine }) => (
    <Pagination
        shape={'rounded'}
        variant={'outlined'}
        count={nbPages}
        size={'large'}
        page={currentRefinement}
        onChange={(e, value) => {
            e.preventDefault()
            refine(value)

        }}
    />
)
const CustomStats = ({processingTimeMS, nbHits}) => (
    <Typography variant={'body2'}>{nbHits > 0 ? `${nbHits} products found in ${processingTimeMS} milliseconds`:'No results found'}</Typography>
)
const Stats = connectStats(CustomStats)
const NewPagination = connectPagination(CustomPagination)

const CustomSortBy = ({ items, createURL, refine }) => {
    const [value, setValue] = React.useState({value: 'products', label: 'Featured'})

    React.useEffect(() => {
        refine(value.value)
    }, [value])

    return(
    <Select options={items} onChange={x => setValue(x)} value={value}/>
    )
}
const NewSortBy = connectSortBy(CustomSortBy)

const CustomRefinementList = ({ items, currentRefinement, refine}) => {
    console.log(items)
    console.log(currentRefinement)
    return(
    <Grid item container xs={12} spacing={0} alignContent={'center'} alignItems={'center'} style={{maxWidth: 300}}>
    {items.map(item => {
        return (
            <>
            <Grid item xs={4} sm={2}>
                <Checkbox checked={item.isRefined} onClick={e => {
                e.preventDefault()
                refine(item.value)
                }}/>
            </Grid>
                <Grid item xs={8} sm={10}>
                    {item.label}
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </>
        )})}
    </Grid>)
}

const RefineCustom = connectRefinementList(CustomRefinementList)


